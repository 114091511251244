import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'articuloOrigen'
})
export class articuloOrigenPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    // 1=Creado Manualmente desde cliente, 2=Creado Manualmente desde administrador, 3=Importado de un xlxs
    switch (value) {
      case '1':
        return '<span class="text-warning">Creado Manualmente por el cliente</span>';
        break;
      case '2':
        return '<span class="text-warning">Creado Manualmente desde administrador</span>';
        break;
      case '3':
        return '<span class="text-success">Importado desde un xlxs</span>';
        break;
    }
    return value;

  }

}
