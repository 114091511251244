// Estados de Campañas RECOGIDAS:
export const MCampanyaEstadosList: any[] = [
    // 1 Pendiente, 2 Parcialmente Asignada, 3 Asignada, 4 En proceso, 5 Incidencia, 6 Completada
    { id: 1, nombre: 'Pendiente' },
    { id: 2, nombre: 'Parcialmente Asignada' },
    { id: 3, nombre: 'Asignada' },
    { id: 4, nombre: 'En proceso' },
    { id: 5, nombre: 'Incidencia' },
    { id: 6, nombre: 'Completada' },
];

// Estados de Campañas PREPARACIÓN:
export const MCampanyaPreparacionEstadosList: any[] = [
    // PREPARACIÓN 1 pendiente, 2 En Proceso, 3- Finalizada, 8 error
    { id: 1, nombre: 'Pendiente' },
    { id: 2, nombre: 'En Proceso' },
    { id: 3, nombre: 'Finalizada' },
    { id: 9, nombre: 'Error' },
];


// Estados de Pedidos Externos: 
export const MPedidosExternosEstadosList: any[] = [
    //1=articulos NO válidos / 2=Pendiente / 3=Cargado / 4 Eliminado
    { id: 1, nombre: 'Artículos NO válidos' },
    { id: 2, nombre: 'Pendiente' },
    { id: 3, nombre: 'Cargado' },
    { id: 4, nombre: 'Eliminado' }
];


// Estados de Pedidos: 
export const MPedidosEstadosList: any[] = [
    { id: 1, nombre: 'Pendiente' },
    { id: 2, nombre: 'Incidencia' },
    { id: 3, nombre: 'Completado' }
];



