import { Component, Input, OnInit } from '@angular/core';
import { IPedido } from 'src/app/interfaces/pedido';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pedido-detall',
  templateUrl: './pedido-detall.component.html',
  styleUrls: ['./pedido-detall.component.scss']
})
export class PedidoDetallComponent implements OnInit {
  @Input() pedidoDetall: IPedido = {}// Detalle de un pedido  

  public fotoDir: string = environment.fotoDir;
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  constructor() { }

  ngOnInit(): void {
    // this.leerTextoConVoz('Hola mundo!');
  }

  leerTextoConVoz(txtVoz: string): void {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(txtVoz);
    synth.speak(utterance);
  }



}
