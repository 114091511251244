import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NotificacionesService } from '../notificaciones.service';
import { UsuarioService } from './usuario.service';
import { environment } from 'src/environments/environment';

// interfaces
import { IApiResp } from 'src/app/interfaces/api-resp';
import { IFacturacion } from 'src/app/interfaces/facturacion';
import { ICliente } from 'src/app/interfaces/cliente';


@Injectable({
  providedIn: 'root'
})
export class FacturacionService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private notificacionesSrv: NotificacionesService,
  ) { }

  facList(id_cliente: string) {
    // Devulve el listado de facturaciones de un cliente
    const datos = { 'id_cliente': id_cliente }
    const path = `${this.apiURL}/facturacion/facList`;
    return this.http.post<IApiResp>(path, datos)
  }

  facEntradas(data: IFacturacion = {}) {
    // Devulve datos de facturación de ENTRADAS de un cliente entre 2 fechas
    const path = `${this.apiURL}/facturacion/facEntradas`;
    return this.http.post<IApiResp>(path, data)
  }


  facSalidas(data: IFacturacion = {}) {
    // Devulve datos de facturación de SALIDAS de un cliente entre 2 fechas
    const path = `${this.apiURL}/facturacion/facSalidas`;
    return this.http.post<IApiResp>(path, data)
  }


  facAlmacenaje(data: IFacturacion = {}) {
    // Devulve datos de facturación de ALMACENAJE de un cliente entre 2 fechas
    const path = `${this.apiURL}/facturacion/facAlmacenaje`;
    return this.http.post<IApiResp>(path, data)
  }

  facSeguros(data: IFacturacion = {}) {
    // Devulve datos de facturación de SEGUROS para un cliente entre 2 fechas
    const path = `${this.apiURL}/facturacion/facSeguros`;
    return this.http.post<IApiResp>(path, data)
  }

  facPdfGlobal(data: any = {}) {
    // Genera un PDF con los datos de daturación para un cliente entre 2 fechas
    const path = `${this.apiURL}/facturacion/facPdfGlobal`;
    return this.http.post<IApiResp>(path, data)
  }




}