<div class="row ">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body animated fadeIn fast">


                <!-- cabecera -->
                <div class="row mb-2">
                    <div class="col-3">
                        <div class="form-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   (keyup)="buscarTodo($event)" />
                        </div>
                    </div>

                    <!-- Pasillos -->
                    <div class="col-md-2">
                        <div class="form-group row ">
                            <label for="example-text-input"
                                   class="col-md-4 col-form-label text-right">Pasillos: </label>
                            <div class="col-md-8">
                                <select class="form-control border border-dark rounded"
                                        name="pasillo"
                                        [(ngModel)]="pasillo"
                                        (change)="filtroUbiChange()">
                                    <option value="*">Todos</option>
                                    <option *ngFor="let val of pasillosList"
                                            [value]="val">
                                        {{val}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>


                    <!-- Alturas -->
                    <div class="col-md-2">
                        <div class="form-group row ">
                            <label for="example-text-input"
                                   class="col-md-4 col-form-label text-right">Alturas: </label>
                            <div class="col-md-8">
                                <select class="form-control border border-dark rounded"
                                        name="altura"
                                        [(ngModel)]="altura"
                                        (change)="filtroUbiChange()">
                                    <option value="*">Todas</option>
                                    <option *ngFor="let alt of alturasList"
                                            [value]="alt">
                                        {{alt}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>


                    <!-- Seleccionar todo -->
                    <div class="col-md-2">
                        <div class="demo-checkbox">
                            <input id="chkMenu"
                                   type="checkbox"
                                   class="filled-in"
                                   [(ngModel)]="selectAllRows"
                                   (ngModelChange)="toggleSelectAllRows()" />
                            <label for="chkMenu">Seleccionar todo </label>
                        </div>
                    </div>

                    <!-- Seleccionar NO asignados -->
                    <div class="col-md-1">
                        <div class="demo-checkbox">
                            <input id="selectNoAssigned"
                                   type="checkbox"
                                   class="filled-in"
                                   [(ngModel)]="selectNoAssigned"
                                   (ngModelChange)="toggleNoAssigned()" />
                            <label for="selectNoAssigned">NO asignados </label>
                        </div>
                    </div>


                    <div class="col-md-2">
                        <form>

                            <div class="row float-right">

                                <div class="col-md-7">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="9999">Todo</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-5">
                                    <div class="form-group float-right">
                                        <div class="btn-group">
                                            <button type="button"
                                                    (click)="refreshData()"
                                                    class="btn btn-outline-info btn-circle"
                                                    title="Refrescar Datos">
                                                <i class="fa-solid fa-refresh"></i>
                                            </button>
                                            <button type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>

                </div>



                <div class="rounded border">
                    <small>
                        Selecciona ubicaciones/artículos para la asignación de la campaña <b>{{campanyaInfo.camp_cod}}</b>
                        <span *ngIf="campanyaInfo.campanyaTipo==='1 a 1'"
                              class="text-warning ml-1"> <i> Campaña tipo pedido 1 a 1</i></span>
                    </small>

                    <!-- datatable -->
                    <ngx-datatable class="bootstrap pointer"
                                   [style.width]="'100%'"
                                   [rows]="rows"
                                   [columnMode]="ColumnMode.flex"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   [messages]="messages"
                                   rowHeight="auto"
                                   [limit]=rowsLimit
                                   [selected]="selected"
                                   [selectionType]="SelectionType.multiClick"
                                   (activate)="onActivate($event)"
                                   [summaryRow]="true"
                                   [summaryHeight]="50"
                                   [summaryPosition]="summaryPosition">



                        <!-- id -->
                        <ngx-datatable-column name="Id"
                                              prop='id'
                                              [flexGrow]="70"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <span [ngClass]="{ 'text-warning': row.estado === 1 }">
                                    <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                                </span>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- recogida_cod -->
                        <ngx-datatable-column name="Cód.recogida"
                                              prop='recogida_cod'
                                              [flexGrow]="110"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <span [ngClass]="{ 'text-warning': row.estado === 1 }">
                                    <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                                </span>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- estado -->
                        <ngx-datatable-column name="Estado"
                                              prop='estado'
                                              [flexGrow]="100"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div *ngIf="row.recogida_cod"
                                     [innerHTML]="value | carritoEstado"></div>
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- id_articulo -->
                        <ngx-datatable-column name="id_articulo"
                                              prop='id_articulo'
                                              [flexGrow]="70"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <span [ngClass]="{ 'text-warning': row.estado === 1 }">
                                    <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                                </span>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- foto -->
                        <ngx-datatable-column name=""
                                              prop='foto'
                                              [flexGrow]="50"
                                              [resizeable]="false"
                                              [sortable]="false">
                            <ng-template ngx-datatable-cell-template
                                         let-row="row"
                                         let-value="value">
                                <div *ngIf="value">
                                    <img [src]="fotoDir +'/articulos' + imgResized + value"
                                         loading="lazy"
                                         alt="Foto"
                                         class="rounded pointer border"
                                         height="25px"
                                         title="foto">
                                </div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- sku -->
                        <ngx-datatable-column name="Sku"
                                              prop='sku'
                                              [flexGrow]="150"
                                              [summaryFunc]="cuentaCeldas">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <span [ngClass]="{ 'text-warning': row.estado === 1 }">
                                    <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                                </span>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- ean -->
                        <ngx-datatable-column name="Ean"
                                              prop='ean'
                                              [flexGrow]="150">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- descripcion -->
                        <ngx-datatable-column name="Descripción"
                                              prop='descripcion'
                                              [maxWidth]="150"
                                              [flexGrow]="150">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(limitarYAgregarPuntos(value, 20), textoDestacado)"
                                     [title]="value"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- ubicacion -->
                        <ngx-datatable-column name="Ubicación"
                                              prop='ubicacion'
                                              [flexGrow]="160">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div class="monospace"
                                     [innerHTML]="resaltarTexto(value | mask: '00.00.00.00.00.00', textoDestacado | mask: '00.00.00.00.00.00')"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- lote -->
                        <ngx-datatable-column name="Lote"
                                              prop='lote'
                                              [maxWidth]="75"
                                              [flexGrow]="75">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"
                                     [title]="row.fechaCaducidad"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- username -->
                        <ngx-datatable-column name="Asignado a"
                                              prop='username'
                                              [flexGrow]="100">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- cant_reserva -->
                        <ngx-datatable-column name="Cantidad"
                                              prop='cant_reserva'
                                              [flexGrow]="85"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- cant_recogida -->
                        <ngx-datatable-column name="Recogido"
                                              prop='cant_recogida'
                                              [flexGrow]="85"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [ngClass]="{
                   'bg-blue rounded':  row.estado === '2',
                   'bg-green rounded': row.estado === '3'
                }"
                                     class="pr-2"
                                     [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>





                        <!-- cant_inicial -->
                        <ngx-datatable-column name="Inicial"
                                              prop='cant_inicial'
                                              [flexGrow]="85"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [ngClass]="{ 'bg-warning rounded':  row.estado === '8' }"
                                     class="pr-2"
                                     [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>





                        <!-- stock -->
                        <ngx-datatable-column name="Stock"
                                              prop='stockUbi'
                                              [flexGrow]="85"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- reservaUbi -->
                        <!-- <ngx-datatable-column name="Reservado"
                                              prop='reservaUbi'
                                              [flexGrow]="85"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column> -->




                        <!-- cant_repartida -->
                        <!-- <ngx-datatable-column name="Preparado"
                                              prop='cant_repartida'
                                              [flexGrow]="85"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [ngClass]="{
                                    'bg-green rounded':  value == row.cant_recogida,
                                    'bg-red rounded':   value > row.cant_recogida
                                 }"
                                     class="pr-2"
                                     [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column> -->



                        <!-- saltar -->
                        <ngx-datatable-column name="Incidencia"
                                              prop='saltar'
                                              [maxWidth]="100"
                                              [flexGrow]="100">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div class="text-danger "
                                     [innerHTML]="resaltarTexto(limitarYAgregarPuntos(value, 20), textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- añadir / editar / borrar -->
                        <ngx-datatable-column [flexGrow]="42"
                                              [sortable]="false"
                                              [resizeable]="false">

                            <ng-template let-row="row"
                                         let-value="value"
                                         ngx-datatable-cell-template>
                                <span *ngIf="!row.ubicacion">
                                    <a (click)="buscaStock(row)"
                                       class="link mr-2 pointer"
                                       title="Buscar Stock">
                                        <span class="badge">S</span>
                                    </a>
                                </span>
                            </ng-template>

                        </ngx-datatable-column>



                    </ngx-datatable>
                </div>


                <div class="card border border-dark rounded mt-4 p-1">
                    <div class="row">

                        <div class="col-md-6">
                            <div class="card bg-light">
                                <div class="card-body">
                                    <p class="card-text">
                                        <span class="h3">{{clienteInfo.nombre}}</span> <br>
                                        Campaña: {{campanyaInfo.camp_cod}} <span class="text-success">{{campanyaInfo.descripcion}} </span><br>
                                        Fecha de entrada: {{campanyaInfo.fecha_entrada}}
                                    </p>
                                    <hr>
                                    <p class="card-text">
                                        Total pedidos: {{campanyaInfo.tot_pedidos | number: '0.0-0':'es'}} <br>
                                        Total artículos: {{campanyaInfo.tot_articulos | number: '0.0-0':'es'}} <br>
                                        Total referencias: {{campanyaInfo.tot_referencias | number: '0.0-0':'es'}} <br>
                                    </p>
                                </div>
                            </div>
                        </div>





                        <div *ngIf="selected.length"
                             class="col-md-6">


                            <!-- form de detall -->
                            <form class="form"
                                  [formGroup]="campForm"
                                  (ngSubmit)="enviar()"
                                  class="form-horizontal">


                                <div>
                                    <div class="form-body">

                                        <div class="card bg-light">
                                            <div class="card-body">

                                                <!-- id_usuario -->
                                                <div class="col-12">
                                                    <div class="form-group"
                                                         [ngClass]="{ 'has-danger': enviado && f['id_usuario'].errors }">
                                                        <label class="form-control-label">Asignar recogida de artículos al usuario:</label>
                                                        <select formControlName="id_usuario"
                                                                class="form-control"
                                                                [ngClass]="{ 'form-control-danger': enviado && f['id_usuario'].errors }">
                                                            <option value=''>Selecciona...</option>
                                                            <option *ngFor="let usuario of userList"
                                                                    [value]="usuario.id">
                                                                <b>{{usuario.username}}</b> - {{usuario.nombre}}
                                                            </option>
                                                        </select>
                                                        <div *ngIf="enviado && f['id_usuario'].errors"
                                                             class="form-control-feedback">
                                                            <div *ngIf="f['id_usuario'].errors['required']">Obligatorio</div>
                                                            <div *ngIf="campForm.get('id_usuario')?.errors?.['apiError']">{{
                                                                campForm.get('id_usuario')?.errors?.['apiError'] }}</div>
                                                        </div>
                                                    </div>

                                                    <hr>
                                                    <p class="card-text">
                                                        Total ubicaciones seleccionadas: {{selected.length | number: '0.0-0':'es'}} <br>
                                                        Total articulos seleccionados: {{totSelCantidad | number: '0.0-0':'es'}} <br>
                                                    </p>



                                                </div>

                                            </div> <!-- card-body -->

                                        </div>
                                    </div>


                                    <hr>

                                    <div class="d-flex">
                                        <button [disabled]="loading"
                                                type="button"
                                                class="btn waves-effect waves-light btn-rounded btn-secondary"
                                                (click)="cancelarSelecctedClick()">Cancelar</button>

                                        <button [disabled]="loading"
                                                type="submit"
                                                class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                            <i *ngIf="loading"
                                               class="fa fa-spinner fa-spin"></i>
                                            Enviar
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>


                        <div *ngIf="!selected.length"
                             class="col-md-6">
                            <button [disabled]="loading"
                                    type="button"
                                    class="btn btn-outline-info btn-rounded mt-2"
                                    [routerLink]="['/panel/asignar_pedidos/', id_campanya]">Ver Preparaciones de {{campanyaInfo.camp_cod}}</button>
                            <br>

                            <button [disabled]="loading"
                                    type="button"
                                    class="btn btn-outline-secondary btn-rounded mt-2"
                                    [routerLink]="'/panel/campanyas_lista'">Volver</button>
                        </div>


                    </div> <!-- row -->
                </div> <!-- card -->



            </div> <!-- card-body -->

        </div>
    </div>


</div>