import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'carritoEstado'
})
export class CarritoEstadoPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    // 1 pendiente, 2 empezada, 3 acabada, 8 error 
    switch (value) {
      case '1':
        return '<span class="text-warning">Pendiente</span>';
        break;
      case '2':
        return '<span class="text-info">Recogida empezada</span>';
        break;
      case '3':
        return '<span class="text-success">Recogida acabada</span>';
        break;
      case '4':
        return '<span class="text-success">Asignado a pedido</span>';
        break;
      case '8':
        return '<span class="text-danger">Incidencia</span>';
    }
    return value;

  }

}
