import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// Interfaces / Modelos
import { IAlmacen } from 'src/app/interfaces/almacenes';
import FuncLib from 'src/app/providers/funcionesLib';

// Servicios
import { AlmacenesService } from 'src/app/services/datos/almacenes.service';
import { AuxDataService } from 'src/app/services/datos/auxData.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-almacen',
  templateUrl: './almacen.component.html',
  styleUrls: ['./almacen.component.scss']
})
export class AlmacenComponent implements OnInit {

  almacen: IAlmacen = {}
  paramId: string = '';
  public listaPob: any[] = [];

  public almForm: FormGroup = new FormGroup({});

  private funcLib: FuncLib = new FuncLib();
  public codUbiDecript: string = '';

  public enviado: boolean = false;
  public loading: boolean = false;

  constructor(
    private almacenesSrv: AlmacenesService,
    private usuarioSrv: UsuarioService,
    private auxDataSrv: AuxDataService,
    private notificacionesSrv: NotificacionesService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    // Suscribirse a los parámetros de la url, si envían una id, cargar los datos del form
    this.activatedRoute.params.subscribe(({ id }) => {
      this.paramId = id;
      if (this.paramId != 'new') this.cargarAlmacen(id);
    })

    this.almForm = this.fb.group({
      codigo: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      nombre: ['', [Validators.minLength(4), Validators.maxLength(150)]],
      descripcion: [''],
      direccion: ['', [Validators.minLength(6), Validators.maxLength(150)]],
      cp: [''],
      poblacion: ['', Validators.required],
      provincia: [''],
      ubiPlaya: [''],
    })
  }

  cargarAlmacen(id: number) {
    this.loading = true;
    this.almacenesSrv.getAlmacen(id)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          this, this.usuarioSrv.logOut();
        } else {
          this.almacen = resp.data;

          this.almForm.patchValue({
            codigo: this.almacen.codigo,
            nombre: this.almacen.nombre,
            descripcion: this.almacen.descripcion,
            direccion: this.almacen.direccion,
            cp: this.almacen.cp,
            poblacion: this.almacen.poblacion,
            provincia: this.almacen.provincia,
            ubiPlaya: this.almacen.ubiPlaya            
          })

          this.buscaPob();
        }
      })
  }

  enviar() {
    if (this.paramId === 'new') {
      this.newAlm();
    } else {
      this.actualizaAlm();
    }
    return
  }


  //  CRUD  // 

  newAlm() {
    this.enviado = true;

    if (this.almForm.invalid) {
      console.log("ERRORES:", this.almForm);
      return;
    }
    // Todo ok, enviar al BackEnd
    this.loading = true;
    this.almacenesSrv.new(this.almForm.value)
      .subscribe(resp => {
        this.loading = false;

        if (resp.error) {
          // this.notificacionesService.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.almForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', `Almacen ${resp.data.nombre} creado correctamente con el <b>id: ${resp.data.id}</b>`);
          this.router.navigateByUrl(`panel/almacenes`);
        }
      })
  }

  actualizaAlm() {
    this.enviado = true;

    if (this.almForm.invalid) {
      console.log("ERRORES:", this.almForm);
      return;
    }
    // Todo ok, enviar al BackEnd
    const datos = {
      ...this.almForm.value,
      id: this.almacen.id
    }
    this.loading = true;
    this.almacenesSrv.update(datos)
      .subscribe(resp => {
        this.loading = false;

        if (resp.error) {
          // this.notificacionesService.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.almForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', `Almacen ${this.almForm.get('nombre')?.value} actualizado correctamente `);
          this.router.navigateByUrl(`panel/almacenes`);
        }
      })
  }

  get f() {
    return this.almForm.controls;
  }


  public cambiaUbi() {
    const ubicacion = this.almForm.get('ubiPlaya')?.value;
    this.codUbiDecript = this.funcLib.CodUbiDecript(ubicacion);
  }

  ///   AUX   ///


  buscaPob() {
    var cp = this.almForm.value.cp;
    this.almForm.patchValue({ poblacion: '', provincia: '' });
    this.listaPob = [];

    if (cp.length < 5) return;

    this.loading = true;
    this.auxDataSrv.getPoblaciones(cp)
      .subscribe((resp) => {
        this.loading = false;
        if (resp.error) {
          this.almForm.controls['cp'].setErrors({ 'noExiste': true });
          return;
        }

        // Lista de Poblaciones
        var pobAux: string = '';
        for (let key of Object.keys(resp.data)) {
          if (typeof resp.data[key] === 'object' && resp.data[key].hasOwnProperty('Poblacion')) {
            pobAux = resp.data[key]['Poblacion'];
            if (!this.listaPob.includes(pobAux)) this.listaPob.push(pobAux);
          }
        }

        this.almForm.patchValue({ poblacion: this.listaPob[0] });
        if (this.listaPob.includes(this.almacen.poblacion)) this.almForm.patchValue({ poblacion: this.almacen.poblacion });


        // Provincia
        this.almForm.patchValue({ provincia: resp.data.provincia.provincia });

      });

  }

}
