// Componentes
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable'

//Modelos e Interfaces
import { IAlmacen } from 'src/app/interfaces/almacenes';
import { ICliente } from 'src/app/interfaces/cliente';
import { IEntrada } from 'src/app/interfaces/entrada';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';
import { IPedido } from 'src/app/interfaces/pedido';
import { IStock } from 'src/app/interfaces/stock';

// Servicios
import { AlmacenesService } from 'src/app/services/datos/almacenes.service';
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { PedidosService } from 'src/app/services/datos/pedidos.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { environment } from 'src/environments/environment';

// Librerias
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
declare var $: any;

@Component({
  selector: 'app-cliente-pedidos-lista',
  templateUrl: './cliente-pedidos-lista.component.html',
  styleUrls: ['./cliente-pedidos-lista.component.scss']
})
export class ClientePedidosListaComponent implements OnInit {
  loading: boolean = false;
  public fotoDir: string = environment.fotoDir;
  
  textoDestacado: string = '';
  public strBuscarTodo: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }

  pedidosList: IPedido[] = [];
  almacenesList: IAlmacen[] = [];
  clientesList: ICliente[] = [];

  rows: any[] = [];
  temp: any[] = [];
  selected: any[] = [];

  columns = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  opcionesList: IListaOpciones = {}

  almSeleccionado: string = '';
  cliSeleccionado: string = '';

  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  // public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimitStr: string = '5';
  public rowsLimit: number = parseInt(this.rowsLimitStr);
  
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';


  @ViewChild(ClientePedidosListaComponent) table: ClientePedidosListaComponent | any;

  constructor(
    public pedidoSrv: PedidosService,
    public almacenesSrv: AlmacenesService,
    public clientesSrv: ClientesService,
    public usuarioSrv: UsuarioService,
    private notificacionesSrv: NotificacionesService
  ) {
  }


  ngOnInit(): void {

    console.log('pedidoSrv.selected', this.pedidoSrv.selected)

    this.getListaPedidos();

    this.almacenesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.almacenesList = respuesta.data;
      })

    this.clientesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.clientesList = respuesta.data;
      })


  }



  getListaPedidos() {
    this.opcionesList.id_cliente = parseInt(this.cliSeleccionado);
   // this.opcionesList.swCompleto = 'isNull';        // Solo registros con swCompleto = NULL
    this.loading = true;

    this.pedidoSrv.getLista(this.opcionesList)
      .subscribe(resp => { 
        console.log('_getListaPedidos()', resp);
        this.loading = false;
        this.pedidosList = resp.data;

        // Seleccionar la línea del servicio, si no hay ninguna, seleccionar la primera de la lista
        if (this.pedidoSrv.selected) {
          const index = this.pedidosList.findIndex(item => item.id === this.pedidoSrv.selected.id);
          this.selected = index !== -1 ? [this.pedidosList[index]] : [this.pedidosList[0]];
        } else {
          this.selected = [this.pedidosList[0]];
        }

        this.rows = this.pedidosList;
        this.temp = [...this.pedidosList];
      })

  }


  // Búscar y filtrar

  filtro(val: string, campo: string) {

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d[campo]) return d[campo].toLowerCase().indexOf(val) !== -1 || !val;
      return !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.pedidosList;
  }

  buscar(event: any, campo: string) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    this.filtro(txt, campo);
  }


  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.pedidosList;
  }


  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }

  onAlmacenSelect(id: string) {
    this.almSeleccionado = id;
    this.getListaPedidos();
  }

  onClienteSelect(id: string) {
    this.cliSeleccionado = id;
    this.getListaPedidos();
  }




  //  CRUD  //

  editRow(row: IEntrada) {
    this.notificacionesSrv.aviso('info', `editar id ${row.id}`)
  }

  deleteRow(row: any) {
    Swal.fire({
      title: '¿Borrar pedido?',
      icon: 'question',
      html: `Eliminar pedido <b>${row.id}</b>`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.pedidoSrv.delete(row.id)
          .subscribe(resp => {
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
            } else {
              this.creaTransaccion(row);  // hay que crear transacción aqui?
              this.notificacionesSrv.aviso('info', `Registro eliminado`);
              this.rows = this.rows.filter((objeto) => objeto.id !== row.id);

              this.selected = [];
              // No quedan más pedidos?
              if (!this.rows.length) {
                this.pedidosList = [];
              }

            }
          })
      }
    })


  }

  creaTransaccion(row: IStock) {
    console.log('Aquí hay que crear una Transaccion?', row)
  }


  onSelect(selected: any) {
    console.log('Select Event', selected, this.selected);
    this.pedidoSrv.selected = this.selected[0];
  }



  //  AUX //


  resaltarTexto(value: string, textoDestacado: string) {
    if (!textoDestacado) {
      return value;
    }
    return value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>');
  }



  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'datos.xlsx');
  }


  public sumaCeldas(cells: any[]): number {
    const filteredCells = cells.filter(cell => !!cell);
    const sum = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    return sum;
  }

  public nada(cells: any[]): string {
    return '';
  }

}
