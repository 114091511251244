<div *ngIf="loading"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Cargando...</h3>
</div>

<div class="row ">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body animated fadeIn fast">


                <!-- cabecera -->
                <div class="row mb-3">

                    <div class="col-6">
                        <div class="form-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   (keyup)="buscarTodo($event)" />
                        </div>
                    </div>

                    <div class="col-3">
                        <div class=" input-group border border-dark rounded">
                            <div class="input-group-addon"><i class="fa-solid fa-warehouse"></i></div>
                            <select class="form-control "
                                    [(ngModel)]="almSeleccionado"
                                    (ngModelChange)="onAlmacenSelect($event)">
                                <option value=0>Todos los almacenes</option>
                                <option *ngFor="let alm of almacenesList"
                                        [value]=alm.id>
                                    {{alm.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>



                    <div class="col-3">
                        <form>

                            <div class="row float-right">
                                <div class="col-sm-6">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="5">5</option>
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="999999">Todo</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group float-right">
                                        <div class="btn-group">
                                            <button type="button"
                                                    [routerLink]="'/panel/entrada_edit/new'"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Nuevo">
                                                <i class="fa-solid fa-plus"></i>
                                            </button>
                                            <button type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>

                </div>



                <div class="rounded border">
                    <!-- ngx-datatable -->
                    <ngx-datatable class="bootstrap"
                                   [style.width]="'100%'"
                                   [rows]="rows"
                                   [columnMode]="ColumnMode.force"
                                   [loadingIndicator]="loading"
                                   [headerHeight]="70"
                                   [footerHeight]="50"
                                   [rowHeight]="50"
                                   [limit]="rowsLimit"
                                   [rows]='rows'
                                   [selected]="selected"
                                   [selectionType]="SelectionType.single"
                                   (select)="onSelect($event)"
                                   [messages]="messages"
                                   [footerHeight]="50"
                                   [summaryRow]="true"
                                   [summaryHeight]="55"
                                   [summaryPosition]="summaryPosition">


                        <!-- id -->
                        <ngx-datatable-column name="id"
                                              prop='id'
                                              [width]="70"
                                              [maxWidth]="70"
                                              [resizeable]="false">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <br>
                                <div class="header">
                                    <h6 class="pointer mt-12">id</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- almNombre -->
                        <ngx-datatable-column prop='almNombre'
                                              name="Almacén"
                                              [width]="70"
                                              [maxWidth]="200">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'almNombre')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Almacén</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- cliNombre -->
                        <ngx-datatable-column name="cliNombre"
                                              [width]="70"
                                              [maxWidth]="200">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'cliNombre')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Cliente</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- albaran -->
                        <ngx-datatable-column name="albaran"
                                              [width]="100"
                                              [maxWidth]="300">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'albaran')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer  link">Albaran</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-rowIndex="rowIndex"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                                <span *ngIf="row.swDefectuosos"
                                      title="Albarán con artículos defectuosos"
                                      class="text-warning ml-12">
                                    <i class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i></span>
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- created_at -->
                        <ngx-datatable-column name="fecha entrada"
                                              prop="created_at"
                                              [width]="90"
                                              [maxWidth]="120">

                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'created_at')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Entrada</h6>
                                </div>
                            </ng-template>

                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>

                                <span *ngIf="row.origen==='1'"
                                      class="text-secondary ml-2"
                                      style="display: inline-block;"
                                      title="Creado por el cliente">
                                    <i class="fa-solid fa-network-wired"></i>
                                </span>

                            </ng-template>

                        </ngx-datatable-column>


                        <!-- proveedor -->
                        <ngx-datatable-column name="proveedor"
                                              [width]="100"
                                              [maxWidth]="300">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'proveedor')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer  link">Proveedor</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- devolucion -->
                        <ngx-datatable-column name="Devolucion"
                                              prop='devolucion'
                                              [width]="100"
                                              [maxWidth]="100">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div title="Entrada por devolución"
                                     [innerHTML]="value | iconCheck"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- username -->
                        <ngx-datatable-column name="username"
                                              [width]="100"
                                              [maxWidth]="150">
                            <ng-template ngx-datatable-header-template
                                         let-sort="
                                              sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'username')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Usuario</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- pales -->
                        <ngx-datatable-column name="pales"
                                              [width]="80"
                                              [maxWidth]="100"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'pales')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Palés</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>{{ value }}</ng-template>
                        </ngx-datatable-column>



                        <!-- Acciones -->
                        <ngx-datatable-column [width]="151"
                                              [sortable]="false"
                                              [resizeable]="false">


                            <ng-template let-row="row"
                                         let-value="value"
                                         ngx-datatable-cell-template>

                                <a (click)="infoClickEntrada(row)"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="INFORMACIÓN de esta entrada">
                                    <span class="badge"> I </span>
                                </a>

                                <a (click)="row.swValidado !== '2' ? editEntrada(row.id) : null"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="VALIDAR entrada"
                                   [ngClass]="{'disabled': row.swValidado === '2'}">
                                    <span class="badge">V</span>
                                </a>

                                <a (click)="row.swValidado === '2' ? editEntrada(row.id) : null"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="MODIFICAR entrada"
                                   [ngClass]="{'disabled': row.swValidado !== '2'}">
                                    <span class="badge">M</span>
                                </a>

                                <a (click)="row.pdf_albaran ? abrirPDF(row) : null"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="Abrir Pdf del ALBARAN"
                                   [ngClass]="{'disabled': !row.pdf_albaran}">
                                    <span class="badge">A</span>
                                </a>

                                <!-- <a [routerLink]="['/panel/entrada_edit',row.id]"
                                   class="link mr-2 pointer"
                                   title="MODIFICAR entrada">
                                    <span class="badge">M</span>
                                </a> -->

                                <!-- 
                                <a (click)="etiquetaPale(row)"
                                   class="link mr-2 pointer"
                                   title="Imprimir ETIQUETAS de palé/s">
                                    <span class="badge">E</span>
                                </a> -->

                                <a (click)="deleteRow(row)"
                                   class="link mr-2 pointer"
                                   title="BORRAR entrada">
                                    <span class="badge">B</span>
                                </a>

                            </ng-template>


                        </ngx-datatable-column>


                    </ngx-datatable>
                </div>

            </div>
        </div>
    </div>



    <!-- Tabla de detalle de la entrada seleccionada -->
    <div *ngIf="entradasList.length"
         class="col-12">

        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Detalle de la entrada</h4>
                <h6 class="card-subtitle">Líneas pendientes de ubicar</h6>
                <div class="bootstrap-table">


                    <div class="table-responsive">
                        <table class="table table-sm table-striped table-bordered table-hover">

                            <thead>
                                <tr>
                                    <th>Palé</th>
                                    <th>Sku</th>
                                    <th>Artículo</th>
                                    <th>Lote</th>
                                    <th style="text-align: center; padding-left: 10px">cantidad</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>

                            <!-- Si hay detall y no está ubicado, mostrarlo -->
                            <tbody *ngIf="selected[0]?.detall">
                                <ng-container *ngFor="let lin of selected[0].detall; let i = index">

                                    <!-- Solo los artículos pendientes de ubicar -->
                                    <tr *ngIf="lin.id_ubicacion == null">
                                        <td>{{ lin.pale }}</td>
                                        <td>
                                            {{ lin.artSku }}
                                            <span *ngIf="lin.swDefectuoso == Const.DEFECTUOSO">
                                                <i title="Defectuoso"
                                                   class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                                            </span>
                                        </td>
                                        <td>{{ lin.artDescripcion }}</td>
                                        <td>{{ lin.lote }}</td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace">
                                                {{lin.cantidad | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td>
                                            <!-- está validado? -->
                                            <span *ngIf="selected[0].swValidado ==='2'">
                                                <a href="javascript: void(0);"
                                                   [routerLink]="'/panel/entrada_ubicar_articulo/' + selected[0].id + '/' + lin.id"
                                                   title="Ubicar artículos">
                                                    <i class="fa-solid fa-box-open"></i>
                                                </a>
                                            </span>
                                        </td>
                                        <td>
                                            <!-- está validado? -->
                                            <span *ngIf="selected[0].swValidado ==='2'">
                                                <!-- Tiene palés para ubicar? -->
                                                <span *ngIf="i == 0 || lin.pale != selected[0].detall[i-1].pale">
                                                    <a href="javascript: void(0);"
                                                       [routerLink]="'/panel/entrada_ubicar_pale/' + selected[0].id + '/' + lin.pale"
                                                       title="Ubicar palé completo">
                                                        <i class="fa-solid fa-dolly"></i>
                                                    </a>
                                                </span>
                                            </span>
                                        </td>
                                    </tr>


                                </ng-container>
                            </tbody>

                        </table>
                    </div>



                </div>
            </div>

            <div class="clearfix"></div>
        </div>

    </div>


</div>