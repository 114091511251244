import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ubiTipoFamilia'
})
export class ubiTipoFamiliaPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case '1':
        return 'Palé';
        break;
      case '2':
        return 'Bandeja';
        break;
    }
    return value;
  }

}
