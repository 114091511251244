import { HttpClient, } from '@angular/common/http';
import { Injectable } from '@angular/core';  
import { environment } from 'src/environments/environment';
import { IEmail } from '../interfaces/email';
import { IApiResp } from '../interfaces/api-resp';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
  ) { }

  enviaEmail(data: IEmail) {
    const path = `${this.apiURL}/enviaEmail`;
    return this.http.post<IApiResp>(path, data)
  }

}
