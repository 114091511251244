import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificacionesService } from '../notificaciones.service';
import { environment } from 'src/environments/environment';
import { IApiResp } from 'src/app/interfaces/api-resp';
import { UsuarioService } from './usuario.service';

@Injectable({
  providedIn: 'root'
})
export class AuxDataService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }

  getPoblaciones(cp: string) {
    // Devulve la lista de poblaciones para un cp
    const path = `${this.apiURL}/getPoblaciones/${cp}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  } 


  getListaServicios() {
    // Devulve la lista de servicios de envío
    const path = `${this.apiURL}/getListaServicios`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  } 


}
