// Módulos
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { catchError, throwError } from 'rxjs';

// Servicios
import { UsuarioService } from './usuario.service';
import { NotificacionesService } from '../notificaciones.service';

// Interfaces
import { IApiResp } from 'src/app/interfaces/api-resp';
import { IPedido } from 'src/app/interfaces/pedido';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';

@Injectable({
  providedIn: 'root'
})
export class PedidosExternosService {

  apiURL: string = environment.apiURL;
  public selected: any;                   // Línea seleccionada

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }


  getPedido(id: number) {
    const path = `${this.apiURL}/pedidosExternos/list/${id}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }


  getPedidoEDetall(id: number) {
    const path = `${this.apiURL}/pedidosExternos/getPedidoEDetall/${id}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }


  getLista(_opciones: any = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/pedidosExternos/listFiltro`;

    const data = {
      id: _opciones.id || null,
      fecha_carga_null: _opciones.fecha_carga_null,
      id_cliente: _opciones.id_cliente || null,
      id_articulo: _opciones.id_articulo || null,
    };

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  delete(id: number) {
    const path = `${this.apiURL}/pedidosExternos/delete/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }


}
