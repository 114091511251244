import { ArticulosService } from 'src/app/services/datos/articulos.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { EntradasService } from 'src/app/services/datos/entradas.service';
import { IEntrada } from 'src/app/interfaces/entrada';

@Component({
  selector: 'app-ubicaciones2',
  templateUrl: './ubicaciones2.component.html',
  styleUrls: ['./ubicaciones2.component.scss']
})
export class Ubicaciones2Component implements OnInit {
  @ViewChild('myTable') table: any;

  entradasList: IEntrada[] = [];

  rows: any[] = [];
  expanded: any = {};
  timeout: any;

  ColumnMode = ColumnMode;

  constructor(
    public entradaSrv: EntradasService,
  ) { }
  ngOnInit(): void {
    this.getListaEntradas();
  }

  getListaEntradas() {
    this.entradaSrv.getLista()
      .subscribe(respuesta => {
        this.entradasList = respuesta.data;
        this.rows = this.entradasList;

        console.log(respuesta)
      })
  }

  onPage(event: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('paged!', event);
    }, 100);
  }


  toggleExpandRow(row: any) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event: any) {
    console.log('Detail Toggled', event);
  }
}