import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// Interfaces / Modelos
import { IAlmacen } from 'src/app/interfaces/almacenes';
import { IUbicacion } from 'src/app/interfaces/ubicacion';
import FuncLib from 'src/app/providers/funcionesLib';

// Servicios  
import { UbicacionesService } from 'src/app/services/datos/ubicaciones.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';


@Component({
  selector: 'app-ubicacion',
  templateUrl: './ubicacion.component.html',
  styleUrls: ['./ubicacion.component.scss']
})
export class UbicacionComponent implements OnInit {

  ubicacion: IUbicacion = {}
  paramId: string = '';

  public listaAlm: IAlmacen[] = [];

  public ubiForm: FormGroup = new FormGroup({});

  public enviado: boolean = false;
  public loading: boolean = false;

  infoStr: string = '';

  private funcLib: FuncLib = new FuncLib();
  public codUbiDecript: string = '';

  constructor(
    private usuarioSrv: UsuarioService,
    private ubiSrv: UbicacionesService,
    private notificacionesSrv: NotificacionesService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    // Suscribirse a los parámetros de la url, si envían una id, cargar los datos del form
    this.activatedRoute.params.subscribe(({ id }) => {
      this.paramId = id;
      if (this.paramId != 'new') this.cargarUbicacion(id);
    })

    this.ubiForm = this.fb.group({
      codigo: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]],
      comentario: ['', [Validators.minLength(4), Validators.maxLength(150)]],
      swOcupado: [''],
      peso: ['1'],
      orden_recogida: ['1']
    })
  }

  cargarUbicacion(id: number) {
    this.loading = true;
    this.ubiSrv.getUbicacion(id)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          this, this.usuarioSrv.logOut();
        } else {
          this.ubicacion = resp.data;

          console.log(this.ubicacion);

          this.ubiForm.patchValue({
            codigo: this.ubicacion.codigo,
            comentario: this.ubicacion.comentario,
            swOcupado: this.ubicacion.swOcupado,
            peso: this.ubicacion.peso,
            orden_recogida: this.ubicacion.orden_recogida
          })
        }
      })
  }

  enviar() {
    if (this.paramId === 'new') {
      this.new();
    } else {
      this.update();
    }
    return
  }


  //  CRUD  // 

  new() {
    this.enviado = true;

    if (this.ubiForm.invalid) {
      console.log("ERRORES:", this.ubiForm);
      return;
    }
    // Todo ok, enviar al BackEnd
    this.loading = true;
    this.ubiSrv.new(this.ubiForm.value)
      .subscribe(resp => {
        this.loading = false;

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.ubiForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', `Ubicación creada correctamente con el <b>id: ${resp.data.id}</b>`);
          this.router.navigateByUrl(`panel/ubicaciones`);
        }
      })
  }

  update() {
    this.enviado = true;

    if (this.ubiForm.invalid) {
      console.log("ERRORES:", this.ubiForm);
      return;
    }
    // Todo ok, enviar al BackEnd
    const datos = {
      ...this.ubiForm.value,
      id: this.ubicacion.id
    }
    this.loading = true;
    this.ubiSrv.update(datos)
      .subscribe(resp => {
        this.loading = false;

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.ubiForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          const strAux = this.ubiForm.get('codigo')?.value
          this.notificacionesSrv.aviso('success', `Ubicación ${strAux} actualizada correctamente `);
          this.router.navigateByUrl(`panel/ubicaciones`);
        }
      })
  }

  get f() {
    return this.ubiForm.controls;
  }


  public cambiaUbi() {
    const ubicacion = this.ubiForm.get('codigo')?.value;
    this.codUbiDecript = this.funcLib.CodUbiDecript(ubicacion);
  }


  ///   AUX   ///

  public infoUbicacion() {
    let info: string[] = [];
    if (this.ubicacion.descripcion !== undefined) {
      info = this.ubicacion.descripcion.split(", ");
    }
    return info;
  }

}
