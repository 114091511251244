import * as Const from '../../../shared/constants';

import { OnDestroy } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable'
import { Subscription, delay } from 'rxjs';

//Modelos e Interfaces
import { IArticulo } from 'src/app/interfaces/articulo';
import { ICliente } from 'src/app/interfaces/cliente';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';

// Servicios 
import { ArticulosService } from 'src/app/services/datos/articulos.service';
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { ModalImagenService } from 'src/app/services/modal-imagen.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// Librerias
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';


interface Editing {
  [key: string]: boolean;
}

@Component({
  selector: 'app-articulos-lista',
  templateUrl: './articulos-lista.component.html',
  styleUrls: ['./articulos-lista.component.scss']
})
export class ArticulosListaComponent implements OnInit, OnDestroy {

  // Usar constantes en el template
  Const = Const

  loading: boolean = false;
  public fotoDir: string = environment.fotoDir;

  public imgSubs: Subscription | undefined;

  textoDestacado: string = '';
  public strBuscarTodo: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }

  articulosList: IArticulo[] = [];
  clientesList: ICliente[] = [];

  rows: IArticulo[] = [];
  temp: any[] = [];

  columns = [];
  ColumnMode = ColumnMode;

  opcionesList: IListaOpciones = {}
  cliSeleccionado: string = '';

  editing: Editing = {};

  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';


  @ViewChild(ArticulosListaComponent) table: ArticulosListaComponent | any;

  constructor(
    public articulosSrv: ArticulosService,
    private notificacionesSrv: NotificacionesService,
    private modalImagenSrv: ModalImagenService,
    private clientesSrv: ClientesService,
  ) { }

  ngOnDestroy(): void {
    this.imgSubs?.unsubscribe();
  }

  ngOnInit(): void {

    this.loading = true;
    this.clientesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.clientesList = respuesta.data;
      })


    this.getListaArtic();

    // Nos suscribimos a la imagen, 
    // Si cambia la imagen, buscamos esa id en this.lista[]

    this.imgSubs = this.modalImagenSrv.nuevaImagen
      .pipe(delay(100))
      .subscribe(img => {
        const indice = this.articulosList.findIndex(art => art.id === img.id);
        this.articulosList[indice].foto = img.guardado_como;
      });

  }

  getListaArtic() {
    this.opcionesList.id_cliente = parseInt(this.cliSeleccionado);

    this.loading = true;
    this.articulosSrv.getListaFiltrada(this.opcionesList)
      .subscribe(respuesta => {
        this.loading = false;
        console.log('__getListaArtic()', respuesta);
        this.articulosList = respuesta.data;
        this.rows = this.articulosList;
        this.temp = [...this.articulosList];
      })

  }


  // Búscar y filtrar

  filtro(val: string, campo: string) {

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d[campo]) return d[campo].toLowerCase().indexOf(val) !== -1 || !val;
      return !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.articulosList;
  }

  buscar(event: any, campo: string) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    this.filtro(txt, campo);
  }


  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.articulosList;
  }



  dblclickCampo(valor: any) {
    this.strBuscarTodo = String(valor);
    const txt = valor.toLowerCase() ?? '';
    this.buscaGlobal(txt);
  }


  buscaGlobal(txt: string) {
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.articulosList;
  }


  resaltarTexto(value: string | null, textoDestacado: string) {
    return value ? value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>') : '';
  }

  limpiaBusca() {
    this.strBuscarTodo = '';
    this.buscaGlobal('');
  }

  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr);
  }


  // Filtros

  onClienteSelect(id: string) {
    this.cliSeleccionado = id;
    this.getListaArtic();
  }


  //  CRUD  //

  editRow(row: IArticulo) {
    this.notificacionesSrv.aviso('info', `editar id ${row.id}`)
  }

  deleteRow(row: any) {
    Swal.fire({
      title: '¿Borrar artículo?',
      icon: 'question',
      html: `Eliminar <b>${row.descripcion}</b>`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.articulosSrv.delete(row.id)
          .subscribe(resp => {
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
            } else {
              this.notificacionesSrv.aviso('info', `Registro eliminado`);
              this.rows = this.rows.filter((objeto) => objeto.id !== row.id);
            }
          })
      }
    })


  }


  abrirModal(row: IArticulo) {
    this.modalImagenSrv.abrirModal('articulos', row.id!.toString(), row.foto);
  }


  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'datos.xlsx');
  }


}
