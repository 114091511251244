import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificacionesService } from '../notificaciones.service';
import { environment } from 'src/environments/environment';
import { IApiResp } from 'src/app/interfaces/api-resp';
import { IAvisos } from 'src/app/interfaces/avisos';

@Injectable({
  providedIn: 'root'
})
export class AvisosService {

  avisosList: IAvisos = {}
  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }


  avisoNew(data: any) {
    // "id_cliente" => "required|integer|existeId[cliente]",
    // "tipo" => "required",
    // "buscar_pedido" => "opcional",
    // "mensaje" => "min_length[4]"
    const path = `${this.apiURL}/avisos/new`;
    return this.http.post<IApiResp>(path, data)
  }

  getLista(id_cliente: number) {
    const path = `${this.apiURL}/avisos/list/${id_cliente}`;
    const lista = this.http.get<IApiResp>(path)
    this.avisosList != lista;
    return lista;
  }
}
