<h4>Estado actual de almacenes</h4>
<br>

<div class="row">

    <div *ngFor="let item of grafData"
         class="col-md-3 col-xs-12 animated fadeIn fast">
        <div class="card border rounded p-1">


            <div class="card-header">
                <h4 *ngIf="item.almacen">{{item.almacen.nombre}}</h4>
                <h6>ALMACEN {{item.almCod}}</h6>
            </div>

            <div class="card-body">
                <canvas baseChart
                        class="chart"
                        [data]="getChartData(item)"
                        [options]="chartOptions"
                        [type]="doughnutChartType">
                </canvas>

                <ul>
                    <li>Ubicaciones: {{item.totalAlmacen | number: '0.0-0':'es'}}</li>
                    <li>Libres: {{item.totalAlmacen-item.ocupados | number: '0.0-0':'es'}} <b class="text-verdeBack">({{item.porcentajeLibres | number: '1.0-2'}} %)</b> </li>
                    <li>Ocupadas: {{item.ocupados | number: '0.0-0':'es'}} <b class="text-rojoBack">({{item.porcentajeOcupadas | number: '1.0-2'}} %)</b></li>
                </ul>

                <span *ngIf="item.campanyasPendientes">
                    <a href="./#/panel/campanyas_lista"
                       target="_self"
                       title="Abrir campañas">
                        <h4>
                            Campañas pendientes: {{item.campanyasPendientes | number: '0.0-0':'es'}}
                        </h4>
                    </a>
                </span>

            </div>




        </div>
    </div>
</div>