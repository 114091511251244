import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IStock } from 'src/app/interfaces/stock';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { ModalRegularizarService } from 'src/app/services/modal-regularizar.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';


@Component({
  selector: 'app-modal-regularizar',
  templateUrl: './modal-regularizar.component.html',
  styleUrls: ['./modal-regularizar.component.scss']
})


export class ModalRegularizarComponent implements OnInit, OnDestroy {

  @Input() stock?: IStock;
  enviado: boolean = false;
  loading: boolean = false;

  public regulForm: FormGroup = new FormGroup({});
  stockSubscription: Subscription | undefined;


  constructor(
    public modalRegularizarSrv: ModalRegularizarService,
    private notificacionesSrv: NotificacionesService,
    private usuarioSrv: UsuarioService,
    private fb: FormBuilder) { }

  ngOnDestroy(): void {
    this.stockSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.stock = this.modalRegularizarSrv.stock;

    this.regulForm = this.fb.group({
      cantidad: [this.stock?.cantidad, [Validators.required, Validators.min(-500), Validators.max(500)]],
      descripcion: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(150)]],
      motivo: ['Regularización'],
      username: [this.usuarioSrv.userdata.username],
    })


    // Suscribirse al objeto stock y actualizar el formulario cuando cambie, así ligamos la regularización a un rec de Stock
    this.stockSubscription = this.modalRegularizarSrv.stockSubject.subscribe((stock: IStock) => {
      this.regulForm.patchValue({
        cantidad: stock?.cantidad,
        descripcion: ''
      });
      this.enviado = false
    });



  }

  cerrarModal() {
    this.modalRegularizarSrv.stock = undefined;
    this.modalRegularizarSrv.cerrarModal();
  }

  cerrarModalClickOutside(event: MouseEvent) {
    // Cerrar modal al hacer click fuera del modal  
    if ((event.target as HTMLElement).classList.contains('modal')) {
      // this.cerrarModal();
    }
  }



  get f() {
    return this.regulForm.controls;
  }


  enviar() {
    this.enviado = true;
    if (this.regulForm.invalid) {
      console.log("ERRORES:", this.regulForm);
      return;
    }
    // Todo ok, enviar al BackEnd
    this.loading = true;
    this.modalRegularizarSrv.stockRegulariza(this.modalRegularizarSrv.stock.id, this.regulForm.value)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.regulForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', `Regularizado correctamente `);
          // Actualizar modalRegularizarSrv.stock.cantidad, para que se actualice en la lista
          this.modalRegularizarSrv.stock.cantidad = resp.data.cantidad;
          this.cerrarModal();
          return
        }
      })


  }


  ///   AUX   ///



}
