<div *ngIf="loading"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Cargando...</h3>
</div>

<div class="row ">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body animated fadeIn fast">


                <!-- cabecera -->
                <div class="row mb-2">

                    <div class="col-6">
                        <div class="form-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   (keyup)="buscarTodo($event)" />
                        </div>
                    </div>

                    <div class="col-6">
                        <form>

                            <div class="row float-right">

                                <div class="col-sm-6">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="999999">Todo</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group float-right">
                                        <div class="btn-group">

                                            <a type="button"
                                               [routerLink]="'/panel/ubi_tipo/new'"
                                               class="btn btn-outline-info btn-circle "
                                               data-toggle="tooltip"
                                               title="Nuevo">
                                                <i class="fa-solid fa-plus"></i>
                                            </a>

                                            <button type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>

                </div>




                <div class="rounded border">
                    <!-- datatable -->
                    <ngx-datatable class="bootstrap"
                                   [rows]="rows"
                                   [limit]="rowsLimit"
                                   [loadingIndicator]="loading"
                                   [columnMode]="ColumnMode.force"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   [summaryRow]="false"
                                   [summaryHeight]="55"
                                   [summaryPosition]="'bottom'"
                                   [rowHeight]="'auto'"
                                   [messages]="messages"
                                   [reorderable]="true">




                        <!-- familia -->
                        <ngx-datatable-column name="Familia"
                                              prop="familia"
                                              [width]="150"
                                              [maxWidth]="150"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div>{{value | ubiTipoFamilia}} </div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- codigo -->
                        <ngx-datatable-column name="Código"
                                              prop='codigo'
                                              [width]="75"
                                              [maxWidth]="75">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- nombre -->
                        <ngx-datatable-column name="Nombre"
                                              prop='nombre'
                                              [width]="200"
                                              [maxWidth]="250">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- largo -->
                        <ngx-datatable-column name="Largo máximo"
                                              prop='largo'
                                              [width]="10"
                                              [maxWidth]="150">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div class="text-right mr-3">
                                    <span [innerHTML]="resaltarTexto(value, textoDestacado) | number: '0.0-0':'es'"></span>
                                    <small *ngIf="value"> cm.</small>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- ancho -->
                        <ngx-datatable-column name="Ancho máximo"
                                              prop="ancho"
                                              [width]="10"
                                              [maxWidth]="150">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div class="text-right mr-3">
                                    <span [innerHTML]="resaltarTexto(value, textoDestacado) | number: '0.0-0':'es'"></span>
                                    <small *ngIf="value"> cm.</small>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- alto -->
                        <ngx-datatable-column name="Alto máximo"
                                              prop='alto'
                                              [width]="10"
                                              [maxWidth]="150">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div class="text-right mr-3">
                                    <span [innerHTML]="resaltarTexto(value, textoDestacado) | number: '0.0-0':'es'"></span>
                                    <small *ngIf="value"> cm.</small>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- peso -->
                        <ngx-datatable-column name="Peso máximo"
                                              prop='peso'
                                              [width]="10"
                                              [maxWidth]="150">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div class="text-right mr-3">
                                    <span [innerHTML]="resaltarTexto(value, textoDestacado) | number: '0.2-2':'es'"></span>
                                    <small *ngIf="value"> kg.</small>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- añadir / editar / borrar -->
                        <ngx-datatable-column [width]="50"
                                              [maxWidth]="100"
                                              [sortable]="false"
                                              [resizeable]="false">


                            <ng-template let-row="row"
                                         let-value="value"
                                         ngx-datatable-cell-template>
                                <!-- 
                                <a [routerLink]="['/panel/ubi_tipo',row.id]"
                                   class="link mr-2"
                                   title="Editar">
                                    <i class="fa-regular fa-pen-to-square fa"></i>
                                </a>

                                <a (click)="deleteRow(row)"
                                   class="link mr-2 pointer"
                                   title="Borrar">
                                    <i class="fa-regular fa-trash-can fa"></i>
                                </a> 
-->



                                <a [routerLink]="['/panel/ubi_tipo',row.id]"
                                   class="link mr-2 pointer"
                                   title="Editar">
                                    <span class="badge">E</span>
                                </a>

                                <a (click)="deleteRow(row)"
                                   class="link mr-2 pointer"
                                   title="Borrar">
                                    <span class="badge">B</span>
                                </a>
                            </ng-template>


                        </ngx-datatable-column>

                    </ngx-datatable>
                </div>

            </div>
        </div>
    </div>

</div>