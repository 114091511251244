<div>
    <h3>    
        <small>
            <a href="javascript:void(0)"
               (click)="table.rowDetail.expandAllRows()">Expand All</a>
            |
            <a href="javascript:void(0)"
               (click)="table.rowDetail.collapseAllRows()">Collapse All</a>
        </small>
    </h3>
    <ngx-datatable #myTable
                   class="bootstrap expandable"
                   [columnMode]="ColumnMode.force"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [rowHeight]="50"
                   [scrollbarV]="false"
                   [rows]="rows"
                   (page)="onPage($event)">


        <!-- Row Detail Template -->
        <ngx-datatable-row-detail [rowHeight]="100"
                                  #myDetailRow
                                  (toggle)="onDetailToggle($event)">
            <ng-template let-row="row"
                         let-expanded="expanded"
                         ngx-datatable-row-detail-template>
                <div style="padding-left:35px;">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-subtitle">Líneas pendientes de ubicar</h6>
                            <div class="bootstrap-table">
                                <div class="table-responsive">
                                    <table class="table table-sm table-bordered table-hover">

                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Palé</th>
                                                <th>Sku</th>
                                                <th>Artículo</th>
                                                <th>cantidad</th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <!-- Si hay detall, mostrarlo -->
                                        <tbody *ngIf="row?.detall">
                                            <ng-container *ngFor="let lin of row.detall; let i = index">
                                                <tr>
                                                    <td>
                                                        <span *ngIf="i == 0 || lin.pale != row.detall[i-1].pale">
                                                            <a href="javascript: void(0);"
                                                               title="Ubicar palé completo">
                                                                <i class="fa-solid fa-dolly"></i>
                                                            </a>
                                                        </span>

                                                    </td>
                                                    <td>{{ lin.pale }}</td>
                                                    <td>{{ lin.artSku }}</td>
                                                    <td>{{ lin.artDescripcion }}</td>
                                                    <td style="text-align: right; padding-left: 10px">{{ lin.cantidad }}</td>
                                                    <td>
                                                        <a href="javascript: void(0);"
                                                           title="Ubicar artículos">
                                                            <i class="fa-solid fa-box-open"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>
                    </div>
                </div>
            </ng-template>
        </ngx-datatable-row-detail>


        <!-- Column Templates -->
        <ngx-datatable-column [width]="50"
                              [resizeable]="false"
                              [sortable]="false"
                              [draggable]="false"
                              [canAutoResize]="false">
            <ng-template let-row="row"
                         let-expanded="expanded"
                         ngx-datatable-cell-template>
                <a href="javascript:void(0)"
                   [class.datatable-icon-right]="!expanded"
                   [class.datatable-icon-down]="expanded"
                   title="Expand/Collapse Row"
                   (click)="toggleExpandRow(row)">
                </a>
            </ng-template>
        </ngx-datatable-column>


        <ngx-datatable-column name="Index"
                              [width]="80">
            <ng-template let-rowIndex="rowIndex"
                         let-row="row"
                         ngx-datatable-cell-template>
                <strong>{{ rowIndex }}</strong>
            </ng-template>
        </ngx-datatable-column>


        <ngx-datatable-column name="albaran"
                              [width]="200">
            <ng-template let-value="value"
                         ngx-datatable-cell-template>
                <strong>{{ value }}</strong>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="cliNombre"
                              [width]="300">
            <ng-template let-row="row"
                         let-value="value"
                         ngx-datatable-cell-template>
                <i [innerHTML]="row['name']"></i> and <i>{{ value }}</i>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="created_at"></ngx-datatable-column>
    </ngx-datatable>
</div>