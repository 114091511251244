import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// interfaces
import { ICliente } from 'src/app/interfaces/cliente';

// servicios
import { FacturacionService } from 'src/app/services/datos/facturacion.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';


// Librerías
import { ColumnMode } from '@swimlane/ngx-datatable';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';

interface IFiles {
  name?: string,
  creation_date?: Date
}

@Component({
  selector: 'app-cliente-fac-lista',
  templateUrl: './cliente-fac-lista.component.html',
  styleUrls: ['./cliente-fac-lista.component.scss']
})
export class ClienteFacListaComponent implements OnInit {

  cliente: ICliente = {}
  paramId: string = '';
  facList: IFiles[] = [];

  rows: any[] = [];
  temp: any[] = [];

  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  textoDestacado: string = '';
  loading = false;

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }

  ColumnMode = ColumnMode;

  @ViewChild(ClienteFacListaComponent) table: ClienteFacListaComponent | any;

  constructor(
    private facturacionSrv: FacturacionService,
    private notificacionesSrv: NotificacionesService,
    private activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {

    // Suscribirse a los parámetros de la url, si envían una id, cargar los datos del cliente
    this.activatedRoute.params.subscribe(({ id }) => {
      this.paramId = id;
      this.getFacList(id)
    })

  }

  getFacList(id_cliente: string) {
    this.facturacionSrv.facList(id_cliente).subscribe(resp => {
      this.loading = false;

      if (resp.error) {
        this.notificacionesSrv.aviso('error', resp.mensaje || 'Error desconocido');
        return
      }
      this.facList = resp.data;
      this.cliente = resp.totales.cliente;

      console.log('__getFacList()', resp);

      this.rows = this.facList;
      this.temp = [...this.facList];
    })


  }

  descargaFicheroClick(row: IFiles) {
    const urlPdf: string = environment.pdfDir + '/facturacion/' + row.name;
    window.open(urlPdf, '_blank');
  }

  /// AUX ///


  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }

  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );
    this.textoDestacado = txt;
    this.rows = temp;
    this.table = this.facList;
  }

  resaltarTexto(value: string, textoDestacado: string) {
    if (!textoDestacado) {
      return value;
    }
    return value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>');
  }

  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'datos.xlsx');
  }





}
