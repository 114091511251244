<div class="row ">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body animated fadeIn fast">


                <!-- cabecera -->
                <div class="row mb-3">

                    <div class="col-6">
                        <div class="form-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   (keyup)="buscarTodo($event)" />
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="input-group border border-dark rounded">
                            <div class="input-group-addon"><i class="fa-solid fa-user"></i></div>
                            <select class="form-control"
                                    [(ngModel)]="cliSeleccionado"
                                    (ngModelChange)="onClienteSelect($event)">
                                <option value=''>Todos los clientes</option>
                                <option *ngFor="let cli of clientesList"
                                        [value]=cli.id>
                                    {{cli.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>



                    <div class="col-3">
                        <form>

                            <div class="row float-right">
                                <div class="col-sm-6">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="5">5</option>
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="999999">Todo</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group float-right">
                                        <div class="btn-group">
                                            <button type="button"
                                                    [routerLink]="'/panel/entrada_edit/new'"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Nuevo">
                                                <i class="fa-solid fa-plus"></i>
                                            </button>
                                            <button type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>

                </div>



                <div class="rounded border">
                    <!-- ngx-datatable -->
                    <ngx-datatable class='bootstrap pointer'
                                   [columns]="columns"
                                   [columnMode]="'force'"
                                   [loadingIndicator]="loading"
                                   [headerHeight]="70"
                                   [footerHeight]="50"
                                   [rowHeight]="50"
                                   [limit]="rowsLimit"
                                   [rows]='rows'
                                   [selected]="selected"
                                   [selectionType]="SelectionType.single"
                                   (select)="onSelect($event)"
                                   [messages]="messages"
                                   [footerHeight]="50"
                                   [summaryRow]="true"
                                   [summaryHeight]="55"
                                   [summaryPosition]="summaryPosition">


                        <!-- id -->
                        <ngx-datatable-column name="id"
                                              prop='id'
                                              [width]="70"
                                              [maxWidth]="70"
                                              [resizeable]="false">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <br>
                                <div class="header">
                                    <h6 class="pointer mt-12">id</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- fecha_entrada -->
                        <ngx-datatable-column name="fecha_entrada"
                                              prop="fecha_entrada"
                                              [flexGrow]="10">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'fecha_entrada')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Entrada</h6>
                                </div>
                            </ng-template>

                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>

                        </ngx-datatable-column>



                        <!-- cliNombre -->
                        <ngx-datatable-column name="cliNombre"
                                              [flexGrow]="20">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'cliNombre')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Cliente</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- albaran -->
                        <ngx-datatable-column name="albaran"
                                              [flexGrow]="20">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'albaran')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer  link">Albaran</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-rowIndex="rowIndex"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                                <span *ngIf="row.swDefectuosos"
                                      title="Albarán con artículos defectuosos"
                                      class="text-warning ml-12">
                                    <i class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i></span>
                            </ng-template>
                        </ngx-datatable-column>





                        <!-- dirección -->
                        <ngx-datatable-column name="direccion"
                                              [flexGrow]="200">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'direccion')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Enviar a</h6>
                                </div>
                            </ng-template>
                            <ng-template let-row="row"
                                         let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(row.direccion+' ('+row.cp+ ' '+row.poblacion+')' , textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- añadir / editar / borrar -->
                        <ngx-datatable-column [width]="50"
                                              [maxWidth]="100"
                                              [sortable]="false"
                                              [resizeable]="false">


                            <ng-template let-row="row"
                                         let-value="value"
                                         ngx-datatable-cell-template>

                                <a [routerLink]="['/panel/pedido_edit',row.id]"
                                   class="link mr-2"
                                   title="Editar">
                                    <i class="fa-regular fa-pen-to-square fa"></i>
                                </a>

                                <a (click)="deleteRow(row)"
                                   class="link mr-2 pointer"
                                   title="Borrar">
                                    <i class="fa-regular fa-trash-can fa"></i>
                                </a>

                            </ng-template>


                        </ngx-datatable-column>


                    </ngx-datatable>
                </div>

            </div>
        </div>
    </div>



    <!-- Tabla de detalle de la entrada seleccionada -->
    <div *ngIf="pedidosList.length"
         class="col-12">

        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Detalle del Pedido</h4>
                <h6 class="card-subtitle">Artículos</h6>
                <div class="bootstrap-table">


                    <div class="table-responsive">
                        <table class="table table-sm table-striped table-bordered table-hover">

                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Sku</th>
                                    <th>Ean</th>
                                    <th>Artículo</th>
                                    <th>Lote</th>
                                    <th>F.Caducidad</th>
                                    <th style="text-align: right; padding-right: 30px">Cantidad</th>
                                </tr>
                            </thead>

                            <!-- Si hay detall y no está ubicado, mostrarlo -->
                            <tbody *ngIf="selected[0]?.detall">
                                <ng-container *ngFor="let lin of selected[0].detall; let i = index">

                                    <!-- Solo los artículos pendientes de ubicar -->
                                    <tr *ngIf="lin.id_ubicacion == null">
                                        <td>
                                            <img [src]="fotoDir +'/articulos' + imgResized +  lin.artFoto"
                                                 loading="lazy"
                                                 alt="Foto del artículo"
                                                 class="rounded border"
                                                 height="25px">
                                        </td>
                                        <td>{{ lin.artSku }}</td>
                                        <td>{{ lin.artEan }}</td>
                                        <td>{{ lin.artDescripcion }}</td>
                                        <td>{{ lin.lote }}</td>
                                        <td>
                                            <span [innerHTML]="lin.fecha_caducidad | iconFechaCaducada"></span>
                                        </td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace">
                                                {{lin.cantidad | number: '0.0-0':'es'}}
                                            </div>
                                        </td>

                                    </tr>

                                </ng-container>
                            </tbody>

                        </table>
                    </div>



                </div>
            </div>

            <div class="clearfix"></div>
        </div>

    </div>


</div>