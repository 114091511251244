<div *ngIf="loading"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Generando datos de facturación...</h3>
</div>


<!-- Seguros -->
<span *ngIf="!isObjectEmpty(totales)">
    <div class="card border border-info rounded">
        <div class="card-body">

            <div class="d-flex justify-content-between align-items-center">
                <h2>Seguro</h2>
                <button class="btn btn-seccondary mb-2"
                        [disabled]="loading"
                        (click)="abrirPdfClick()">
                    <i class="fa fa-spinner fa-spin"
                       *ngIf="loading"></i> Abrir detalle</button>
            </div>

            <!-- RESUMEN -->

            <table class="table table-sm table-striped table-bordered table-hover">

                <thead>
                    <tr>
                        <th style="text-align: right;"> </th>
                        <th style="text-align: right;">Valor de la mercancía</th>
                        <th style="text-align: right;">Porcentaje</th>
                        <th style="text-align: right;">Importe del seguro</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="text-align: right;">Precio fijo</td>
                        <td style="text-align: right;"> </td>
                        <td style="text-align: right;"> </td>
                        <td style="text-align: right;">{{ totales.cliente.fac_seguro_eur | euroCurrency }} </td>
                    </tr>

                    <tr>
                        <td style="text-align: right;">Porcentaje sobre valor</td>
                        <td style="text-align: right;">{{ totales.cliente.fac_seguro_valor | euroCurrency }} </td>
                        <td style="text-align: right;"> {{ totales.cliente.fac_seguro_porcentaje | number: '0.0':'es'}} %</td>
                        <td style="text-align: right;">{{ totales.cliente.fac_seguro_calculado | euroCurrency }} </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr class="total-row">
                        <td style="text-align: right;"><strong>Total</strong></td>
                        <td></td>
                        <td></td>
                        <td style="text-align: right;"><strong>{{ totales.cliente.fac_seguro_total | euroCurrency }}</strong></td>
                    </tr>
            </table>
        </div>
    </div>
</span>



<!-- Sin datos -->
<span *ngIf="sinDatos">
    <div class="card border border-info rounded">
        <div class="card-body">
            <h2>SEGURO</h2>
            <h3 class="text-success"> Sin Datos</h3>
        </div>
    </div>
</span>