<div class="row ">

  <div class="col-12">
    <div class="card">
      <div class="card-body animated fadeIn fast">


        <!-- cabecera -->
        <div class="row mb-2">

          <div class="col-6">
            <div class="form-group border border-dark rounded">
              <input class="form-control"
                     type="text"
                     autofocus
                     placeholder="Buscar cualquier dato..."
                     (keyup)="buscarTodo($event)" />
            </div>
          </div>

          <div class="col-6">
            <form>

              <div class="row float-right">

                <div class="col-sm-6">
                  <div class="form-group rounded">
                    <select class="form-control"
                            name="rowsLimitStr"
                            [(ngModel)]="rowsLimitStr"
                            (change)="rowsLimitChange()">
                      <option [value]="10">10</option>
                      <option [value]="50">50</option>
                      <option [value]="100">100</option>
                      <option [value]="999999">Todo</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group float-right">
                    <div class="btn-group">
                      <button type="button"
                              [routerLink]="'/panel/usuario/new'"
                              class="btn btn-outline-info btn-circle"
                              data-toggle="tooltip"
                              title="Nuevo">
                        <i class="fa-solid fa-plus"></i>
                      </button>
                      <button type="button"
                              (click)="exportToExcel()"
                              class="btn btn-outline-info btn-circle"
                              data-toggle="tooltip"
                              title="Descargar Excel">
                        <i class="fa-solid fa-download"></i>
                      </button>
                    </div>
                  </div>
                </div>

              </div>

            </form>
          </div>

        </div>



        <div class="rounded border">
          <!-- datatable -->
          <ngx-datatable class="bootstrap"
                         [rows]="rows"
                         [limit]="rowsLimit"
                         [loadingIndicator]="loading"
                         [columnMode]="ColumnMode.force"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [summaryRow]="false"
                         [summaryHeight]="55"
                         [summaryPosition]="'bottom'"
                         [rowHeight]="'auto'"
                         [reorderable]="true">

            <!-- foto -->
            <ngx-datatable-column name=""
                                  prop="foto"
                                  [width]="50"
                                  [maxWidth]="70"
                                  [sortable]="false">
              <ng-template ngx-datatable-cell-template
                           let-row="row"
                           let-value="value">


                <div *ngIf="value">
                  <img [src]="fotoDir +'/users' + imgResized + value"
                       loading="lazy"
                       (click)="abrirModal(row)"
                       alt="Foto"
                       class="rounded pointer"
                       height="25px"
                       title="Cambiar/borrar foto">
                </div>
              </ng-template>
            </ngx-datatable-column>


            <!-- username -->
            <ngx-datatable-column name="username"
                                  [width]="50"
                                  [maxWidth]="150">
              <ng-template ngx-datatable-header-template
                           let-sort="sortFn"
                           let-column="column">

                <div class="header"
                     (click)="sort()">
                  <h6 class="title link">Usuario</h6>
                </div>
              </ng-template>
              <ng-template let-value="value"
                           let-row="row"
                           ngx-datatable-cell-template>
                <span [ngClass]="{'text-danger': row.activo != '1'}"
                      [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                <span *ngIf="row.activo != '1'"
                      class="ml-1"
                      title="Usuario bloqueado">
                  <i class="text-danger fa-solid fa-ban"></i>
                </span>
              </ng-template>
            </ngx-datatable-column>


            <!-- nombre -->
            <ngx-datatable-column name="nombre"
                                  [width]="100"
                                  [maxWidth]="150">
              <ng-template ngx-datatable-header-template
                           let-sort="sortFn"
                           let-column="column">
                <div class="header"
                     (click)="sort()">
                  <h6 class="title link">Nombre</h6>
                </div>
              </ng-template>
              <ng-template let-value="value"
                           let-row="row"
                           ngx-datatable-cell-template>
                <div [ngClass]="{'text-danger': row.activo != '1'}"
                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
              </ng-template>
            </ngx-datatable-column>


            <!-- apellidos -->
            <ngx-datatable-column name="apellidos"
                                  [width]="100"
                                  [maxWidth]="150">
              <ng-template ngx-datatable-header-template
                           let-sort="sortFn"
                           let-column="column">
                <div class="header"
                     (click)="sort()">
                  <h6 class="title link">Apellidos</h6>
                </div>
              </ng-template>
              <ng-template let-value="value"
                           let-row="row"
                           ngx-datatable-cell-template>
                <div [ngClass]="{'text-danger': row.activo != '1'}"
                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
              </ng-template>
            </ngx-datatable-column>


            <!-- email -->
            <ngx-datatable-column name="email">
              <ng-template ngx-datatable-header-template
                           let-sort="sortFn"
                           let-column="column">
                <div class="header"
                     (click)="sort()">
                  <h6 class="title link">e-Mail</h6>
                </div>
              </ng-template>
              <ng-template let-value="value"
                           let-row="row"
                           ngx-datatable-cell-template>
                <div [ngClass]="{'text-danger': row.activo != '1'}"
                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
              </ng-template>
            </ngx-datatable-column>


            <!-- telefono -->
            <ngx-datatable-column name="telefono"
                                  [width]="10">
              <ng-template ngx-datatable-header-template
                           let-sort="sortFn"
                           let-column="column">
                <div class="header"
                     (click)="sort()">
                  <h6 class="title link">Teléfono</h6>
                </div>
              </ng-template>
              <ng-template let-value="value"
                           let-row="row"
                           ngx-datatable-cell-template>
                <div [ngClass]="{'text-danger': row.activo != '1'}"
                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
              </ng-template>
            </ngx-datatable-column>




            <!-- rol -->
            <ngx-datatable-column name="rol"
                                  [width]="50"
                                  [maxWidth]="150">
              <ng-template ngx-datatable-header-template
                           let-sort="sortFn"
                           let-column="column">
                <select class="form-control form-control-sm"
                        (change)="buscaRol($event.target )">
                  <option value="">Todos</option>
                  <option *ngFor="let rol of roles"
                          [value]="rol.valor">{{ rol.texto }}</option>
                </select>


              </ng-template>
              <ng-template let-value="value"
                           let-row="row"
                           ngx-datatable-cell-template>
                <span *ngIf="value === 'sa'"
                      class="rolUsr bg-info">Super Admin</span>
                <span *ngIf="value === 'admin'"
                      class="rolUsr bg-warning">Admin</span>
                <span *ngIf="value === 'cliente'"
                      class="rolUsr bg-success">Cliente</span>
                <span *ngIf="value === 'usuario'"
                      class="rolUsr bg-light">{{value | titlecase }}</span>

              </ng-template>
            </ngx-datatable-column>






            <!-- rolIntranet -->
            <ngx-datatable-column name="rolIntranet"
                                  [width]="10">
              <ng-template ngx-datatable-header-template
                           let-sort="sortFn"
                           let-column="column">
                <div class="header"
                     (click)="sort()">
                  <h6 class="title link">Rol Intranet</h6>
                </div>

              </ng-template>
              <ng-template let-value="value"
                           ngx-datatable-cell-template>
                {{value}}
              </ng-template>
            </ngx-datatable-column>







            <!-- timestamp -->
            <ngx-datatable-column name="timestamp"
                                  [width]="1">
              <ng-template ngx-datatable-header-template
                           let-sort="sortFn"
                           let-column="column">
                <div class="header"
                     (click)="sort()">
                  <h6 class="title link">Último LogIn</h6>
                </div>
              </ng-template>
              <ng-template let-value="value"
                           ngx-datatable-cell-template>
                <span *ngIf="value">{{ value | friendlyTimestamp }}</span>
              </ng-template>
            </ngx-datatable-column>



            <!-- añadir / editar / borrar -->
            <ngx-datatable-column [width]="50"
                                  [maxWidth]="100"
                                  [sortable]="false"
                                  [resizeable]="false">


              <ng-template let-row="row"
                           let-value="value"
                           ngx-datatable-cell-template>


                <a [routerLink]="['/panel/usuario',row.id]"
                   class="link mr-2 pointer"
                   title="EDITAR usuario">
                  <span class="badge">E</span>
                </a>


                <a (click)="['admin', 'sa'].includes(row.rol) === false ? deleteRow(row) : null"
                   class="link mr-2 pointer"
                   data-toggle="tooltip"
                   title="BORRAR usuario"
                   [ngClass]="{'disabled':['admin', 'sa'].includes(row.rol) === true}">
                  <span class="badge">B</span>
                </a>



              </ng-template>


            </ngx-datatable-column>





          </ngx-datatable>
        </div>

      </div>
    </div>
  </div>

</div>