import { Injectable } from '@angular/core';
import { Subject, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IApiResp } from '../interfaces/api-resp';
import { HttpClient } from '@angular/common/http';
import { NotificacionesService } from './notificaciones.service';
import { UsuarioService } from './datos/usuario.service';
import { IStock } from '../interfaces/stock';

@Injectable({
  providedIn: 'root'
})
export class ModalRegularizarService {
  apiURL: string = environment.apiURL;

  private _ocultarModal: boolean = true;
  private _stock: any;

  stockSubject: Subject<IStock> = new Subject<IStock>();

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private notificacionesSrv: NotificacionesService
  ) { }


  get stock(): any {
    return this._stock;
  }

  set stock(value: any) {
    this._stock = value;
    this.stockSubject.next(value);
  }

  get ocultarModal() {
    return this._ocultarModal;
  }


  stockRegulariza(id: any, form: any) {
    const path = `${this.apiURL}/stock/regulariza/${id}`;
    
    console.log(form);
    
    return this.http.post<IApiResp>(path, form)
      .pipe(
        catchError(error => {
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  abrirModal() {
    this._ocultarModal = false;
  }

  cerrarModal() {
    this._ocultarModal = true;
  }

  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }

}
