<div class="row animated fadeIn fast">

    <div class="col-lg-12">
        <div class="card">

            <div class="card-body">
                <form class="form p-t-20"
                      [formGroup]="almForm"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">
                    <div class="form-body">

                        <div class="row">


                            <!-- codigo -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['codigo'].errors }">
                                    <label class="form-control-label">Cód. ubicación:</label>
                                    <input type="text"
                                           formControlName="codigo"
                                           class="form-control"
                                           maxlength="2"
                                           [ngClass]="{ 'form-control-danger': enviado && f['codigo'].errors }">

                                    <div *ngIf="enviado && f['codigo'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['codigo'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['codigo'].errors['minlength']">Deben ser 2 caracteres</div>
                                        <div *ngIf="f['codigo'].errors['maxlength']">Deben ser 2 caracteres</div>
                                        <div *ngIf="f['codigo'].errors?.['apiError']">{{almForm.get('codigo')?.errors?.['apiError']}}</div>
                                    </div>
                                </div>
                            </div>


                            <!-- nombre -->
                            <div class="col-md-3">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['nombre'].errors }">
                                    <label class="form-control-label">Nombre:</label>
                                    <input type="text"
                                           formControlName="nombre"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['nombre'].errors }">

                                    <div *ngIf="enviado && f['nombre'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['nombre'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['nombre'].errors['minlength']">Debe contener un mínimo de 4 caracteres</div>
                                        <div *ngIf="f['nombre'].errors['maxlength']">Nombre no puede exceder de 150 caracteres</div>
                                        <div *ngIf="f['nombre'].errors?.['apiError']">{{almForm.get('nombre')?.errors?.['apiError']}}</div>
                                    </div>
                                </div>
                            </div>

                            <!-- descripcion -->
                            <div class="col-md-7">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['descripcion'].errors }">
                                    <label class="form-control-label">Descripción:</label>
                                    <input type="text"
                                           formControlName="descripcion"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['descripcion'].errors }">

                                    <div *ngIf="enviado && f['descripcion'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['descripcion'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['descripcion'].errors['minlength']">Debe contener un mínimo de 4 caracteres</div>
                                        <div *ngIf="f['descripcion'].errors['maxlength']">Nombre no puede exceder de 150 caracteres</div>
                                        <div *ngIf="f['descripcion'].errors?.['apiError']">{{almForm.get('descripcion')?.errors?.['apiError']}}</div>
                                    </div>
                                </div>
                            </div>



                            <!-- direccion -->
                            <div class="col-md-5">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['direccion'].errors }">
                                    <label class="form-control-label">Dirección:</label>
                                    <input type="text"
                                           formControlName="direccion"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['direccion'].errors }">

                                    <div *ngIf="enviado && f['direccion'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['direccion'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['direccion'].errors['minlength']">Como mínimo 6 caracteres</div>
                                        <div *ngIf="f['direccion'].errors['maxlength']">Como máximo 150 caracteres</div>
                                        <div *ngIf="f['direccion'].errors?.['apiError']">{{almForm.get('direccion')?.errors?.['apiError']}}</div>
                                    </div>
                                </div>
                            </div>




                            <!-- cp -->
                            <div class="form-group col-md-2">
                                <div class="form-floating mb-3">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger':   f['cp'].errors }">
                                        <label class="form-control-label">cp:</label>
                                        <input formControlName="cp"
                                               class="form-control"
                                               [ngClass]="{ 'form-control-danger': f['cp'].errors }"
                                               maxlength="5"
                                               (keyup)="buscaPob()" />

                                        <div *ngIf="f['cp'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['cp'].errors['noExiste']">No existe</div>
                                            <div *ngIf="f['cp'].errors?.['apiError']">{{almForm.get('cp')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- poblacion -->
                            <div class="form-group col-md-3">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['poblacion'].errors }">
                                    <label class="form-control-label">Población:</label>
                                    <select formControlName="poblacion"
                                            class="form-control"
                                            [ngClass]="{ 'form-control-danger': enviado && f['poblacion'].errors }">
                                        <option *ngFor="let pob of listaPob"
                                                [value]="pob">
                                            {{pob}}
                                        </option>
                                    </select>
                                    <div *ngIf="enviado && f['poblacion'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['poblacion'].errors?.['apiError']">{{almForm.get('poblacion')?.errors?.['apiError']}}</div>
                                    </div>
                                </div>

                            </div>

                            <!-- provincia -->
                            <div class="form-group col-md-2">
                                <label for="strProOri">Provincia:</label>
                                <input class="form-control readonly"
                                       formControlName="provincia"
                                       readonly>
                            </div>



                            <!-- ubiPlaya -->
                            <div *ngIf="paramId != 'new'"
                                 class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{'has-danger': enviado && f['ubiPlaya'].errors }">
                                    <label class="form-control-label">Playa por defecto:</label>
                                    <input type="text"
                                           formControlName="ubiPlaya"
                                           (input)="cambiaUbi()"
                                           mask="00.00.00.00.00.00"
                                           class="form-control grande"
                                           style="font-family: 'Verdana', monospace; letter-spacing: 0.6px;"
                                           [ngClass]="{ 'form-control-danger': enviado && f['ubiPlaya'].errors }">

                                    <div *ngIf="enviado && f['ubiPlaya'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['ubiPlaya'].errors['minlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['ubiPlaya'].errors['maxlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['ubiPlaya'].errors?.['apiError']">{{almForm.get('ubiPlaya')?.errors?.['apiError']}}</div>
                                    </div>
                                    <small>{{codUbiDecript}}</small>
                                </div>
                            </div>




                        </div>


                        <div class="d-flex">
                            <button [disabled]="loading"
                                    type="button"
                                    class="btn waves-effect waves-light btn-rounded btn-secondary"
                                    [routerLink]="'/panel/almacenes'"
                                    data-dismiss="modal">Cancelar</button>
                            <h6 class="card-subtitle ml-4 ">
                                <li *ngIf="almacen.created_at"> Creado hace {{almacen.created_at! | friendlyDdmmyy}}</li>
                                <li *ngIf="almacen.updated_at"> Última modificación hace {{almacen.updated_at! | friendlyDdmmyy}}</li>
                            </h6>
                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Enviar</button>
                        </div>

                    </div>

                </form>
            </div>
        </div>
    </div>

</div>