import * as Const from '../../../shared/constants';

// Componentes  
import { Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable'

//Modelos e Interfaces
import { IAlmacen } from 'src/app/interfaces/almacenes';
import { ICliente } from 'src/app/interfaces/cliente';
import { IPreparaciones } from 'src/app/interfaces/preparaciones';
import { IStock } from 'src/app/interfaces/stock';

// Servicios
import { AlmacenesService } from 'src/app/services/datos/almacenes.service';
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { PreparacionesService } from 'src/app/services/datos/preparaciones.service';
import { StockService } from 'src/app/services/datos/stock.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { ModalRegularizarService } from 'src/app/services/modal-regularizar.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// Librerias
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-preparaciones-lista',
  templateUrl: './preparaciones-lista.component.html',
  styleUrls: ['./preparaciones-lista.component.scss']
})
export class PreparacionesListaComponent implements OnInit {
  
  //Usar constantes en el template
  Const = Const;

  loading: boolean = false;
  textoDestacado: string = '';

  // Valores desde el padre
  @Input() strBuscarTodo: string = '';
  @Input() almSeleccionado: string | null = null;
  @Input() cliSeleccionado: string | null = null;

  ngOnChanges(changes: SimpleChanges): void {

    // Check si almSeleccionado cambia
    if (changes['almSeleccionado']) {
      console.log('__ngOnChanges almSeleccionado cambia:', changes['almSeleccionado'].currentValue);

      this.opcionesList.id_almacen = parseInt(changes['almSeleccionado'].currentValue);
      this.getListaPrep();
    }

    // Check si cliSeleccionado cambia
    if (changes['cliSeleccionado']) {
      console.log('__ngOnChanges cliSeleccionado cambia:', changes['cliSeleccionado'].currentValue);

      this.opcionesList.id_cliente = parseInt(changes['cliSeleccionado'].currentValue);
      this.getListaPrep();
    }


    // Check si strBuscarTodo cambia
    if (changes['strBuscarTodo']) {
      console.log('__ngOnChanges strBuscarTodo cambia:', changes['strBuscarTodo'].currentValue);

      const txt = changes['strBuscarTodo'].currentValue.toLowerCase() ?? '';
      this.buscaGlobal(txt);
    }

  }

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }

  preparacionesList: IPreparaciones[] = [];
  almacenesList: IAlmacen[] = [];
  clientesList: ICliente[] = [];

  rows: any[] = [];
  temp: any[] = [];

  columns = [];
  ColumnMode = ColumnMode;



  opcionesList: {
    id_cliente?: number,
    id_almacen?: number,
    id_articulo?: number,
    id_ubicacion?: number
  } = {}


  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);


  @ViewChild(PreparacionesListaComponent) table: PreparacionesListaComponent | any;

  constructor(
    private prepracionSrv: PreparacionesService,
    private notificacionesSrv: NotificacionesService,
  ) { }


  ngOnInit(): void {
    this.getListaPrep();
  }


  getListaPrep() {
    this.loading = true;

    this.prepracionSrv.getLista(this.opcionesList)
      .subscribe(respuesta => {
        this.loading = false;
        this.preparacionesList = respuesta.data;

        console.log('opcionesList', this.opcionesList);
        console.log('__getListaPrep', respuesta);

        this.rows = this.preparacionesList;
        this.temp = [...this.preparacionesList];
      })

  }


  // Búscar y filtrar

  filtro(val: string, campo: string) {

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d[campo]) return d[campo].toLowerCase().indexOf(val) !== -1 || !val;
      return !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.preparacionesList;
  }

  buscar(event: any, campo: string) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    this.filtro(txt, campo);
  }


  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    this.buscaGlobal(txt);
  }



  dblclickCampo(valor: any) {
    this.strBuscarTodo = String(valor);
    const txt = valor.toLowerCase() ?? '';
    this.buscaGlobal(txt);
  }


  buscaGlobal(txt: string) {
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.preparacionesList;
  }

  limpiaBusca() {
    this.strBuscarTodo = '';
    this.buscaGlobal('');
  }

  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }



  //  CRUD  // 


  creaTransaccion(row: IStock) {
    console.log('creaTransaccion', row)
  }


  //  AUX //


  resaltarTexto(value: string, textoDestacado: string) {
    if (!textoDestacado) {
      return value;
    }
    return value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>');
  }



  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'datos.xlsx');
  }

  public sumaCeldas(cells: any[]) {
    // Devuelve la suma de la columna, formateada y sin decimales
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    // return sum.toLocaleString('es');
    return sum.toLocaleString('de-DE');

  }


}
