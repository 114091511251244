import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificacionesService } from '../notificaciones.service';
import { environment } from 'src/environments/environment';
import { IApiResp } from 'src/app/interfaces/api-resp';
import { catchError, throwError } from 'rxjs';
import { UsuarioService } from './usuario.service';
import { IArticulo } from 'src/app/interfaces/articulo';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';

@Injectable({
  providedIn: 'root'
})
export class CampanyasService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
  ) { }

  // Cabecera HttpHeaders Authorization
  // get headers() {
  //   const token = localStorage.getItem('token') || '';
  //   const cabeceras = new HttpHeaders().append('Authorization', 'Bearer ' + token);
  //   return { headers: cabeceras }
  // }

  getCampanya(id: number) {
    const path = `${this.apiURL}/campanyas/list/${id}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  getLista() {
    const heders = true;
    const path = `${this.apiURL}/campanyas/list`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }


  getListaFiltrada(_opciones: IListaOpciones = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/campanyas/listFiltro`;

    const data = {
      id_cliente: _opciones.id_cliente || null,
      id_articulo: _opciones.id_articulo || null,
      estado: _opciones.estado || null,
      estadoPreparacion: _opciones.estadoPreparacion || null
    };

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  campanyasListConPedido(buscarPedido: string) {
    // Devulve la lista de campañas con el pedido $buscarPedido
    const path = `${this.apiURL}/campanyas/campanyasListConPedido`;

    const data = {
      buscarPedido: buscarPedido || null
    };

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }


  asignaUsr(data: any) {
    // Asigna un usuario a un grupo de artículos de una campaña
    const path = `${this.apiURL}/campanyas/asignaUsr`;

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }


  getTotPendientes() {
    const heders = true;
    const path = `${this.apiURL}/campanyas/getTotPendientes`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }


  new(formData: any) {
    const path = `${this.apiURL}/campanyas/new`;
    return this.http.post<IApiResp>(path, formData)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      );
  }

  update(articulo: IArticulo) {
    const path = `${this.apiURL}/campanyas/update/${articulo.id}`;

    return this.http.post<IApiResp>(path, articulo)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  delete(id: number) {
    const path = `${this.apiURL}/campanyas/delete/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }


}
