import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsuarioService } from './datos/usuario.service';
import { NotificacionesService } from './notificaciones.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IApiResp } from '../interfaces/api-resp';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private router: Router,
    private notificacionesSrv: NotificacionesService,) { }

    

  getInfo() {
    // Devulve la lista 
    const path = `${this.apiURL}/info`;
    const info = this.http.get<string>(path, { responseType: 'text'as 'json' });
    return info;
  }


  getPoblaciones(cp: string) {
    const path = `${this.apiURL}/busca_cp/${cp}`;
    return this.http.get<IApiResp>(path);
  }
}
