<div *ngIf="loadingAlbaran"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Generando albarán...</h3>
</div>


<div class="row ">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body animated fadeIn fast">


                <!-- cabecera  -->
                <div class="row mb-2">

                    <!-- <div class="col-3">
                        <div class="form-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   (keyup)="buscarTodo($event)" />
                        </div>
                    </div> -->




                    <div class="col-2">
                        <div class="input-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   [(ngModel)]="strBuscarTodo"
                                   (keyup)="buscarTodo()" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary"
                                        type="button"
                                        title="Limpiar búsqueda"
                                        (click)="limpiaBusca()">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>



                    <!-- pedidosSearch -->
                    <div class="col-2">
                        <div class="input-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   placeholder="Buscar pedido.."
                                   [(ngModel)]="pedidosSearch"
                                   (keydown.enter)="buscarPedido($event)" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary"
                                        type="button"
                                        title="Limpiar búsqueda"
                                        (click)="limpiaBuscaPedido()">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>



                    <!-- cliSeleccionado -->
                    <div class="col-2">
                        <div class="input-group border border-dark rounded">
                            <div class="input-group-addon"><i class="fa-solid fa-user"></i></div>
                            <select class="form-control"
                                    [(ngModel)]="cliSeleccionado"
                                    (ngModelChange)="onClienteSelect($event)">
                                <option value=''>Todos los clientes</option>
                                <option *ngFor="let cli of clientesList"
                                        [value]=cli.id>
                                    {{cli.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- estadoSeleccionado -->
                    <div class="col-2">
                        <div class="input-group border border-dark rounded">
                            <div class="input-group-addon">R</div>
                            <select class="form-control"
                                    [(ngModel)]="estadoSeleccionado"
                                    (ngModelChange)="onEstadoSelect($event)">
                                <option value=''>Todos los estados</option>
                                <option *ngFor="let estado of estadosList"
                                        [value]=estado.id>
                                    {{estado.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>


                    <!-- estadoPreparacionSeleccionado -->
                    <div class="col-2">
                        <div class="input-group border border-dark rounded">
                            <div class="input-group-addon">P</div>
                            <select class="form-control"
                                    [(ngModel)]="estadoPreparacionSeleccionado"
                                    (ngModelChange)="onEstadoPreparacionSelect($event)">
                                <option value=''>Todos los estados</option>
                                <option *ngFor="let estado of estadosPreparacionList"
                                        [value]=estado.id>
                                    {{estado.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>

                    
                    <div class="col-2">
                        <form>

                            <div class="row float-right">

                                <div class="col-sm-6">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="999999">Todo</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group float-right">
                                        <div class="btn-group">

                                            <a type="button"
                                               [routerLink]="'/panel/campanya_new'"
                                               class="btn btn-outline-info btn-circle "
                                               data-toggle="tooltip"
                                               title="Nuevo">
                                                <i class="fa-solid fa-plus"></i>
                                            </a>

                                            <button type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>

                </div>


                <div class="rounded border">
                    <!-- datatable -->
                    <ngx-datatable class="bootstrap"
                                   [style.width]="'100%'"
                                   [limit]="rowsLimit"
                                   [rows]="rows"
                                   [columnMode]="ColumnMode.force"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   [messages]="messages"
                                   rowHeight="auto"
                                   [summaryRow]="true"
                                   [summaryHeight]="50"
                                   [summaryPosition]="summaryPosition">



                        <!-- camp_cod -->
                        <ngx-datatable-column name="Código"
                                              prop='camp_cod'
                                              [width]="115"
                                              [maxWidth]="115"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div class="monospace"
                                     [ngClass]="{'text-warning': row.campanyaTipo === '1 a 1'}"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- created_at -->
                        <ngx-datatable-column name="Fecha"
                                              prop='created_at'
                                              [width]="180"
                                              [maxWidth]="180"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div class="monospace"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)">
                                </div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- cliNombre -->
                        <ngx-datatable-column name="Cliente"
                                              prop='cliNombre'
                                              [width]="160"
                                              [maxWidth]="160"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>


                            </ng-template>
                        </ngx-datatable-column>

                        <!-- estado -->
                        <ngx-datatable-column name="Recogida"
                                              prop='estado'
                                              [width]="140"
                                              [maxWidth]="140"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="value | campanyaEstado"></div>
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- estado_preparacion -->
                        <ngx-datatable-column name="Preparación"
                                              prop='estado_preparacion'
                                              [width]="130"
                                              [maxWidth]="130"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="value | campanyaEstadoPreparacion"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- tot_pedidos -->
                        <ngx-datatable-column name="Pedidos"
                                              prop='tot_pedidos'
                                              class="text-right"
                                              title="Número de pedidos para esta campaña"
                                              [cellClass]="'text-right'"
                                              [maxWidth]="85"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- tot_articulos -->
                        <ngx-datatable-column name="Articulos"
                                              prop='tot_articulos'
                                              class="text-right"
                                              title="Suma de artículos en todos los pedidos"
                                              [cellClass]="'text-right'"
                                              [maxWidth]="85"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- tot_referencias -->
                        <ngx-datatable-column prop='tot_referencias'
                                              name="Refs."
                                              class="text-right"
                                              title='Número de referencias únicas en todos los pedidos'
                                              [cellClass]="'text-right'"
                                              [maxWidth]="85"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- descripcion -->
                        <ngx-datatable-column name="Descripción"
                                              prop='descripcion'
                                              [width]="260"
                                              [maxWidth]="460">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- Acciones -->
                        <ngx-datatable-column name="Acciones"
                                              [width]="151"
                                              [sortable]="false"
                                              [resizeable]="false">


                            <ng-template let-row="row"
                                         let-value="value"
                                         ngx-datatable-cell-template>

                                <a (click)="infoClickCampanya(row)"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="INFORMACIÓN de esta campaña">
                                    <span class="badge"
                                          [ngClass]="{'infoSelect': row.id == campSel.id}">
                                        I
                                    </span>
                                </a>


                                <a (click)="['1', '2', '3'].includes(row.estado) ? deleteRow(row) : null"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="BORRAR esta campaña"
                                   [ngClass]="{'disabled': ['1', '2', '3'].includes(row.estado) === false}">
                                    <span class="badge">B</span>
                                </a>


                                <a [routerLink]="['/panel/preparacion_new', row.id]"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="Info sobre RECOGIDAS de esta campaña">
                                    <span class="badge">R</span>
                                </a>


                                <a [routerLink]="['/panel/asignar_pedidos',row.id]"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="Info sobre PREPARACIONES de esta campaña">
                                    <span class="badge">P</span>
                                </a>


                                <a (click)=" ['1', '2'].includes(row.estado_preparacion) ? campanyaAvisarCliente(row) : null"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="AVISAR al cliente sobre los pedidos pendientes de esta campaña"
                                   [ngClass]="{'disabled': ['1', '2'].includes(row.estado_preparacion) === false}">
                                    <span class="badge">A</span>
                                </a>


                            </ng-template>

                        </ngx-datatable-column>


                    </ngx-datatable>
                </div>

            </div>
        </div>
    </div>






    <!-- Tabla de detalle de la campaña seleccionada -->
    <div *ngIf="pedidosList.length"
         class="col-12">

        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Detalle de la campaña <span class="text-success">{{campSel.descripcion}}</span></h4>
                <h5 class="text-info">{{campSel.camp_cod}} - {{campSel.cliNombre}}
                    <span *ngIf="campSel.campanyaTipo==='1 a 1'"
                          class="text-warning"><i>Campaña tipo pedido 1 a 1</i></span>
                </h5>
                <h6 class="card-subtitle">Líneas resúmen de los pedidos</h6>
                <div class="bootstrap-table">


                    <div class="table-responsive">
                        <table class="table table-sm table-striped table-bordered table-hover">

                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Pedido</th>
                                    <th>Albarán</th>
                                    <th style="text-align: center; padding-left: 10px">Artículos</th>
                                    <th style="text-align: center; padding-left: 10px">Referencias</th>
                                    <th>Población</th>
                                    <th>País</th>
                                    <th>Serv.</th>
                                    <th>Nombre</th>
                                    <th></th>
                                    <th>Estado</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>

                            <!-- Si hay detall y no está ubicado, mostrarlo -->
                            <tbody>
                                <ng-container *ngFor="let pedido of pedidosList; let i = index">
                                    <tr>
                                        <td>{{pedido.id}}</td>
                                        <td>
                                            {{ pedido.pedido_cli }}
                                            <span *ngIf="pedido.swDefectuoso == Const.DEFECTUOSO">
                                                <i title="Defectuoso"
                                                   class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                                            </span>
                                        </td>
                                        <td> {{ pedido.albaran }}</td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace">
                                                {{ pedido.tot_articulos | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace">
                                                {{ pedido.tot_referencias | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td> {{ pedido.cp }}-{{pedido.poblacion }}</td>
                                        <td> {{ pedido.pais_iso }}</td>
                                        <td> {{ pedido.cod_servicio }}</td>
                                        <td> {{ pedido.nombre }}</td>
                                        <td>
                                            <span *ngIf="pedido.aviso_cliente==='1'"
                                                  data-toggle="tooltip"
                                                  title="Cliente avisado, puede solicitar acciones especiales mientras esté en estado 'Pendiente'">
                                                <i class="fa-regular fa-bell"></i>
                                            </span>
                                        </td>
                                        <td>
                                            <div [innerHTML]=" pedido.estado | pedidoEstado "></div>
                                        </td>

                                        <td>
                                            <a (click)="infoClickPedido(pedido.id!)"
                                               class="link mr-2 pointer"
                                               title="INFORMACIÓN del pedido">
                                                <span class="badge"
                                                      [ngClass]="{'infoSelect': pedido.id == pedidoDetall.id}">
                                                    I
                                                </span>
                                            </a>

                                            <a (click)="(pedido.estado === '3' || pedido.estado === '5' ) ? descargaAlbaranClick(pedido.id!) : null"
                                               class="link mr-2 pointer"
                                               title="Descargar ALBARÁN"
                                               [ngClass]="{'disabled':( pedido.estado !== '3' && pedido.estado !== '5') }">
                                                <span class="badge">A</span>
                                            </a>

                                            <a (click)="pedido.estado === '1' ? accionEspecialPedidoClick(pedido.id!) : null"
                                               class="link mr-2 pointer"
                                               title="Ación ESPECIAL sobre el pedido"
                                               [ngClass]="{'disabled': pedido.estado !== '1'}">
                                                <span class="badge">E</span>
                                            </a>

                                            <a (click)="abrirModal(pedido)"
                                               class="link mr-2 pointer"
                                               title="Cantidad de PACKAGING utilizado">
                                                <span class="badge"
                                                      [ngClass]="{ 'yellow-bg': pedido.fac_pack_estado === '2' }">P</span>
                                            </a>

                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td colspan="3"></td>
                                    <td style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 mr-20 monospace">
                                            {{ sumaTotArticulos | number: '0.0-0':'es' }}
                                        </div>
                                    </td>
                                    <td style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 mr-20 monospace">
                                            {{ sumaTotReferencias | number: '0.0-0':'es' }}
                                        </div>
                                    </td>
                                    <td colspan="4"></td>
                                </tr>
                            </tfoot>

                        </table>
                    </div>



                </div>
            </div>

            <div class="clearfix"></div>
        </div>

    </div>




    <!-- Tabla de detalle del pedido -->
    <div *ngIf="pedidoDetall.pedido_cli"
         class="col-12">
        <app-pedido-detall [pedidoDetall]="pedidoDetall"></app-pedido-detall>
    </div>




</div>


<!-- modal para facturación de pedidos -->
<app-modal-fact-salida></app-modal-fact-salida>