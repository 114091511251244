import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'euroCurrency'
})
export class EuroCurrencyPipe implements PipeTransform {
  transform(value: any): string {
    // Verificar si el valor es un número
    if (typeof value !== 'number') {
      // Devolver el valor original si no es un número
      return value;
    }

    // Formatear el número con espacios como separadores de miles y coma como separador decimal
    const parts = value.toFixed(2).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Añadir espacios como separadores de miles
    return parts.join(',') + ' €'; // Unir las partes y añadir el símbolo de euro
  }
}
