
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// Interfaces / Modelos 
import { IUbiTipo } from 'src/app/interfaces/ubicacion';

// Servicios 
import { AuxDataService } from 'src/app/services/datos/auxData.service';
import { UbiTiposService } from 'src/app/services/datos/ubi_tipos.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// Librerias
import CustomVal from 'src/app/providers/CustomValidators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ubi-tipo',
  templateUrl: './ubi-tipo.component.html',
  styleUrls: ['./ubi-tipo.component.scss']
})
export class UbiTipoComponent implements OnInit {

  ubiTipo: IUbiTipo = {}
  paramId: string = '';

  public ubiTipoForm: FormGroup = new FormGroup({});

  public enviado: boolean = false;
  public loading: boolean = false;

  constructor(
    private ubiTiposSrv: UbiTiposService,
    private usuarioSrv: UsuarioService,
    private auxDataSrv: AuxDataService,
    private notificacionesSrv: NotificacionesService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    // Suscribirse a los parámetros de la url, si envían una id, cargar los datos del form
    this.activatedRoute.params.subscribe(({ id }) => {
      this.paramId = id;
      if (this.paramId != 'new') this.cargarubiTipo(id);
    })

    this.ubiTipoForm = this.fb.group({
      familia: ['1', Validators.required],
      codigo: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      nombre: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(150)]],
      largo: ['', CustomVal.esEntero],
      ancho: ['', CustomVal.esEntero],
      alto: ['', CustomVal.esEntero],
      peso: ['']
    })
  }

  cargarubiTipo(id: number) {
    this.loading = true;
    this.ubiTiposSrv.getUbiTipo(id)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          this, this.usuarioSrv.logOut();
        } else {
          this.ubiTipo = resp.data;

          this.ubiTipoForm.patchValue({
            familia: this.ubiTipo.familia,
            codigo: this.ubiTipo.codigo,            
            nombre: this.ubiTipo.nombre,
            alto: this.ubiTipo.alto,
            ancho: this.ubiTipo.ancho,
            largo: this.ubiTipo.largo,
            peso: this.ubiTipo.peso
          })
        }
      })
  }

  enviar() {
    if (this.paramId === 'new') {
      this.new();
    } else {
      this.actualiza();
    }
    return
  }


  //  CRUD  // 

  new() {
    this.enviado = true;

    if (this.ubiTipoForm.invalid) {
      console.log("ERRORES:", this.ubiTipoForm);
      return;
    }
    // Todo ok, enviar al BackEnd
    this.loading = true;
    this.ubiTiposSrv.new(this.ubiTipoForm.value)
      .subscribe(resp => {
        this.loading = false;

        if (resp.error) {
          // this.notificacionesService.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.ubiTipoForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', `Tipo de ubicación ${resp.data.nombre} creada correctamente con el <b>id: ${resp.data.id}</b>`);
          this.router.navigateByUrl(`panel/ubi_tipos`);
        }
      })
  }

  actualiza() {
    this.enviado = true;

    if (this.ubiTipoForm.invalid) {
      console.log("ERRORES:", this.ubiTipoForm);
      return;
    }
    // Todo ok, enviar al BackEnd
    const datos = {
      ...this.ubiTipoForm.value,
      id: this.ubiTipo.id
    }
    this.loading = true;
    this.ubiTiposSrv.update(datos)
      .subscribe(resp => {
        this.loading = false;

        if (resp.error) {
          // this.notificacionesService.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.ubiTipoForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', `Tipo de ubicación ${this.ubiTipoForm.get('nombre')?.value} actualizada correctamente `);
          this.router.navigateByUrl(`panel/ubi_tipos`);
        }
      })
  }

  get f() {
    return this.ubiTipoForm.controls;
  }


  ///   AUX   ///

}
