<div class="row animated fadeIn fast">

    <div class="col-lg-12">
        <div class="card">

            <div class="card-body">
                <form class="form p-t-20"
                      [formGroup]="ubiTipoForm"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">
                    <div class="form-body">

                        <div class="row">

                            <!-- codigo -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['codigo'].errors }">
                                    <label class="form-control-label">Código:</label>
                                    <input type="text"
                                           formControlName="codigo"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['codigo'].errors }">

                                    <div *ngIf="enviado && f['codigo'].errors"
                                         class="form-control-feedback">
                                         <div *ngIf="f['codigo'].errors['required']">Obligatorio</div>
                                         <div *ngIf="f['codigo'].errors['minlength']">Debe contener 2 caracteres</div>
                                         <div *ngIf="f['codigo'].errors['maxlength']">Debe contener 2 caracteres</div>
                                        <div *ngIf="ubiTipoForm.get('codigo')?.errors?.['apiError']">
                                            {{ubiTipoForm.get('codigo')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- familia -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['familia'].errors }">
                                    <label class="form-control-label">Familia:</label>
                                    <select formControlName="familia"
                                            class="form-control"
                                            [ngClass]="{ 'form-control-danger': enviado && f['familia'].errors }">
                                        <option value="1">Palé</option>
                                        <option value="2">Bandeja</option>
                                    </select>

                                    <div *ngIf="enviado && f['familia'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['familia'].errors['required']">Obligatorio</div>
                                        <div *ngIf="ubiTipoForm.get('familia')?.errors?.['apiError']">
                                            {{ubiTipoForm.get('familia')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- nombre -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['nombre'].errors }">
                                    <label class="form-control-label">Nombre:</label>
                                    <input type="text"
                                           formControlName="nombre"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['nombre'].errors }">

                                    <div *ngIf="enviado && f['nombre'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['nombre'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['nombre'].errors['minlength']">Debe contener un mínimo de 4 caracteres</div>
                                        <div *ngIf="f['nombre'].errors['maxlength']">Nombre no puede exceder de 150 caracteres</div>
                                        <div *ngIf="ubiTipoForm.get('nombre')?.errors?.['apiError']">
                                            {{ubiTipoForm.get('nombre')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> <!-- row -->


                        <div class="row">

                            <!-- largo -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['largo'].errors }">
                                    <label class="form-control-label">Largo máximo:</label>
                                    <input type="number"
                                           step='1'
                                           formControlName="largo"
                                           class="form-control">
                                    <small>cm</small>
                                    <div *ngIf="enviado && f['largo'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['largo'].errors['numEntero']">Debe ser un entero</div>
                                        <div *ngIf="ubiTipoForm.get('largo')?.errors?.['apiError']">
                                            {{ubiTipoForm.get('largo')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- ancho -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['ancho'].errors }">
                                    <label class="form-control-label">Ancho máximo:</label>
                                    <input type="number"
                                           step='1'
                                           formControlName="ancho"
                                           class="form-control">
                                    <small>cm</small>
                                    <div *ngIf="enviado && f['ancho'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['ancho'].errors['numEntero']">Debe ser un entero</div>
                                        <div *ngIf="ubiTipoForm.get('ancho')?.errors?.['apiError']">
                                            {{ubiTipoForm.get('ancho')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- alto -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['alto'].errors }">
                                    <label class="form-control-label">Alto máximo:</label>
                                    <input type="number"
                                           step='1'
                                           formControlName="alto"
                                           class="form-control">
                                    <small>cm</small>
                                    <div *ngIf="enviado && f['alto'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['alto'].errors['numEntero']">Debe ser un entero</div>
                                        <div *ngIf="ubiTipoForm.get('alto')?.errors?.['apiError']">
                                            {{ubiTipoForm.get('alto')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- peso -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['peso'].errors }">
                                    <label class="form-control-label">Peso máximo:</label>
                                    <input type="number"
                                           step='0.05'
                                           formControlName="peso"
                                           class="form-control">
                                    <small>Kg</small>
                                    <div *ngIf="enviado && f['peso'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['peso'].errors['required']">Obligatorio</div>
                                        <div *ngIf="ubiTipoForm.get('peso')?.errors?.['apiError']">
                                            {{ubiTipoForm.get('peso')?.errors?.['apiError'] }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> <!-- row -->



                        <div class="d-flex">
                            <button [disabled]="loading"
                                    type="button"
                                    class="btn waves-effect waves-light btn-rounded btn-secondary"
                                    [routerLink]="'/panel/ubi_tipos'"
                                    data-dismiss="modal">Cancelar
                            </button>
                            <h6 class="card-subtitle ml-4 ">
                                <li *ngIf="ubiTipo.created_at"> Creado hace {{ubiTipo.created_at! | friendlyDdmmyy}}</li>
                                <li *ngIf="ubiTipo.updated_at"> Última modificación hace {{ubiTipo.updated_at! | friendlyDdmmyy}}</li>
                            </h6>
                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Enviar
                            </button>
                        </div>


                    </div> <!-- form-body -->

                </form>

            </div>
        </div>
    </div>

</div>