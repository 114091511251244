import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IFacturacion } from 'src/app/interfaces/facturacion';
import { FacturacionService } from 'src/app/services/datos/facturacion.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-facturacion-entradas',
  templateUrl: './entradas.component.html',
  styleUrls: ['./entradas.component.scss']
})
export class FacturacionEntradasComponent implements OnInit {

  @Input() datosCabecera: IFacturacion = {}
  @Output() totalEntradas = new EventEmitter<any>();

  public loading: boolean = false;
  public sinDatos: boolean = false;

  datosFacturacion: any[] = [];
  totales: any = {};

  constructor(
    private facturaSrv: FacturacionService,
    private notificacionesSrv: NotificacionesService
  ) { }

  ngOnInit(): void { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['datosCabecera'] && this.datosCabecera && Object.keys(this.datosCabecera).length > 0) {
      this.enviarDatos();
    }
  }

  enviarDatos(): void {
    // 'devolucion' con el valor 0
    this.datosCabecera.devolucion = 0;

    console.log('__entradas/enviar() OK: ', this.datosCabecera);

    this.loading = true;
    this.facturaSrv.facEntradas(this.datosCabecera).subscribe(resp => {
      this.loading = false;
      this.sinDatos = false;

      console.log('__entradas/facturacion()', resp);

      if (resp.error) {
        this.notificacionesSrv.aviso('error', resp.mensaje);
        return;
      }

      this.datosFacturacion = resp.data.entradas;
      this.totales = resp.totales;

      // Emitir total al padre
      this.totalEntradas.emit(this.totales.entradas);

      if (!this.datosFacturacion || this.datosFacturacion.length === 0) {
        this.sinDatos = true;
      }

      console.log('__entradas/datosFacturacion', this.datosFacturacion);
    });
  }

  abrirPdfClick() {
    console.log('__abrirPdfClick()', this.totales.pdfName);
    const urlPdf: string = environment.pdfDir + '/facturacion/' + this.totales.pdfName;
    window.open(urlPdf, '_blank');
  }
}