import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'campanyaEstado'
})
export class CampanyaEstadoPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    // 1 Pendiente, 2 Recogida parcialmente Asignada, 3 Recogida Asignada, 4 Recogida En proceso, 6 Recogida Completada, 8 Incidencia en Recogida,

    switch (value) {
      case '1':
        return '<span class="text-info">Pendiente</span>';
        break;
      case '2':
        return '<span class="text-info">Parcialmente Asignada</span>';
        break;
      case '3':
        return '<span class="text-warning">Asignada</span>';
        break;
      case '4':
        return '<span class="text-info">En proceso</span>';
        break;
      case '6':
        return '<span class="text-success">Completada</span>';
        break;
      case '8':
        return '<span class="text-danger">Incidencia en Recogida</span>';
        break;
    }
    return value;

  }

}
