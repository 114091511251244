import { CampanyasService } from 'src/app/services/datos/campanyas.service';
import { Component, OnInit } from '@angular/core';
import { ChartData, ChartEvent, ChartType } from 'chart.js';

// Servicios
import { UbicacionesService } from 'src/app/services/datos/ubicaciones.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css'],

})
export class PanelComponent implements OnInit {

  grafData: any[] = [];
  ubiData: any[] = [];
  campData: any[] = [];

  chartOptions = {
    responsive: true,
    animation: {
      duration: 0
    }
  };

  constructor(
    private ubicacionSrv: UbicacionesService,
    private campanyasSrv: CampanyasService
  ) { }

  ngOnInit(): void {
    this.ubicacionSrv.getTotalUbis().subscribe(resp => {
      this.ubiData = resp.data;
      console.log('ubiData', this.ubiData);

      this.campanyasSrv.getTotPendientes().subscribe(resp => {
        this.campData = resp.data;
        console.log('campData', this.campData);

        this.grafData = this.uneObjs()!;
        console.log('grafData', this.grafData);
      })
    })
  }



  getChartData(item: any): ChartData {
    const ocupados = +item.ocupados;
    const disponibles = +item.totalAlmacen - ocupados;
    const colores = {
      'verdeBack': 'rgba(239,83,80, 0.8)',
      'verdeHover': 'rgba(239,83,80, 1)',
      'rojoBack': 'rgba(102,187,106, 0.8)',
      'rojoHover': 'rgba(102,187,106, 1)'
    }

    return {
      labels: ['Ocupado', 'Disponible'],
      datasets: [
        {
          data: [ocupados, disponibles],
          backgroundColor: [colores.verdeBack, colores.rojoBack],
          hoverBackgroundColor: [colores.verdeHover, colores.rojoHover]
        }
      ]
    };
  }



  public doughnutChartType: ChartType = 'doughnut';

  // events
  public chartClicked({ event, active }: { event: ChartEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: ChartEvent, active: {}[] }): void {
    console.log(event, active);
  }


  // Unir resultados de ubicaciones y campañas en un objeto:

  private uneObjs() {
    const resultado = this.ubiData.map(item => {
      const totalAlmacen = item.totalAlmacen;
      const ocupados = item.ocupados;
      const libres = totalAlmacen - ocupados;

      const updatedItem = {
        ...item,
        porcentajeLibres: (libres / totalAlmacen) * 100,
        porcentajeOcupadas: (ocupados / totalAlmacen) * 100
      };

      if (item.almacen && item.almacen.id) {
        const almacenId = item.almacen.id;
        const objeto2Encontrado = this.campData.find(obj => obj.id_almacen === almacenId);

        if (objeto2Encontrado) {
          updatedItem.campanyasPendientes = objeto2Encontrado.pendientes;
        }
      }

      return updatedItem;
    });

    return resultado;
  }





}
