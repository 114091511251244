// Módulos
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

// Interfaces y Modelos 
import { ICliente } from 'src/app/interfaces/cliente';
import { IPedidoExterno } from 'src/app/interfaces/pedido_externo';

// Servicios
import { CampanyasService } from 'src/app/services/datos/campanyas.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { PedidosExternosService } from 'src/app/services/datos/pedidosExternos.service';

// Librerías
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-campanya-new',
  templateUrl: './campanya-new.component.html',
  styleUrls: ['./campanya-new.component.scss']
})
export class CampanyaNewComponent implements OnInit {

  public campForm: FormGroup = new FormGroup({});
  public enviado: boolean = false;
  public loading: boolean = false;

  public fotoDir: string = environment.fotoDir;

  public lista: IPedidoExterno[] = [];
  public clientesList: ICliente[] = [];

  public cuentaClientes: number = 0;

  selected: any = [];
  SelectionType = SelectionType;
  selectAllRows: boolean = false;

  public rows: any = [];
  public temp: any[] = [];

  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';
  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  textoDestacado: string = '';

  opcionesList: any = {}
  cliSeleccionado: string = '';
  pedidosList: any[] = [];         // Información de un pedido
  public pedidoInfo: any = {};
  campanyaTipo: string = 'Normal';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: `total`,
    selectedMessage: `seleccionados`
  }

  ColumnMode = ColumnMode;
  @ViewChild(CampanyaNewComponent) table: CampanyaNewComponent | any;

  constructor(
    private fb: FormBuilder,
    private campanyaSrv: CampanyasService,
    private pedidosExternosSrv: PedidosExternosService,
    private clientesSrv: ClientesService,
    private notificacionesSrv: NotificacionesService,
    private router: Router,

  ) {
    // Definir formulario 
    this.campForm = this.fb.group({
      descripcion: [''],
    })
  }

  ngOnInit(): void {
    this.cargaClientes();
    this.cargaPedidosList();
  }

  cargaPedidosList() {
    this.opcionesList.id_cliente = parseInt(this.cliSeleccionado);
    this.opcionesList.fecha_carga_null = 1;   //fecha_carga == null

    this.loading = true;
    this.pedidosExternosSrv.getLista(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;

        console.log('__cargaPedidosList', resp);

        this.lista = resp.data;

        this.rows = this.lista;
        this.temp = [...this.lista];
        if (resp.totales.noActualizados) {
          this.notificacionesSrv.aviso('warning', resp.totales.noActualizados + ' Artículos NO encontrados');
        }
        if (resp.totales.contaSinStock) {
          this.notificacionesSrv.aviso('info', resp.totales.contaSinStock + ' Artículos SIN Stock suficiente');
        }
        if (resp.totales.pedidosSinDetall) {
          this.notificacionesSrv.aviso('error', resp.totales.pedidosSinDetall + ' Cabeceras de Pedido sin líneas de detalle');
        }
        if (resp.totales.pedidosSinPedCli) {
          this.notificacionesSrv.aviso('error', resp.totales.pedidosSinPedCli + ' Pedidos sin Número de pedido desde el cliente');
        }
        if (resp.totales.pedidosSinIdCli) {
          this.notificacionesSrv.aviso('error', resp.totales.pedidosSinIdCli + ' Pedidos de clientes NO reconocidos');
        }
        if (resp.totales.pedidosDuplicados) {
          this.notificacionesSrv.aviso('error', resp.totales.pedidosDuplicados + ' Pedidos ya existentes');
        }

      })
  }

  cargaClientes() {
    this.clientesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.clientesList = respuesta.data;
      })
  }

  unoAunoClick() {
    this.campanyaTipo = '1 a 1';
    this.enviar();
  }

  enviar() {
    this.enviado = true;

    console.log('__enviar()', this.campForm);

    if (this.campForm.invalid) {
      console.log("ERRORES:", this.campForm);
      return;
    }

    // Todo ok, enviar al BackEnd 
    const datos = {
      ...this.campForm.value,
      pedidos: this.selected,
      campanyaTipo: this.campanyaTipo,
      fecha_entrada: new Date()
    }

    this.loading = true;
    console.log("_datos=", datos);
    this.campanyaSrv.new(datos)
      .subscribe(resp => {
        this.loading = false;

        console.log(resp);

        if (resp.error) {
          // this.notificacionesService.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.campForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', resp.mensaje);
          this.router.navigateByUrl(`panel/campanyas_lista`);
        }
      })
  }


  get f() {
    return this.campForm.controls;
  }


  // Filtros

  onClienteSelect(id: string) {
    this.cliSeleccionado = id;
    this.cargaPedidosList();

    this.selectAllRows = false;
    this.selected = [];
    this.pedidosList = [];
    this.pedidoInfo = {};
  }


  //  ** CRUD ** //   


  deleteRow(row: any) {
    Swal.fire({
      title: '¿Borrar campaña?',
      icon: 'question',
      html: `Eliminar <b>${row.descripcion}</b>`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.campanyaSrv.delete(row.id)
          .subscribe(resp => {
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
            } else {
              this.notificacionesSrv.aviso('info', `Registro eliminado`);
              this.rows = this.rows.filter((objeto: any) => objeto.id !== row.id);
            }
          })
      }
    })
  }



  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }

  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );
    this.textoDestacado = txt;
    this.rows = temp;
    this.table = this.lista;
  }

  resaltarTexto(value: string | null, textoDestacado: string) {
    return value ? value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>') : '';
  }

  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'datos.xlsx');
  }




  public sumaCeldas(cells: any[]) {
    // Devuelve la suma de la columna, formateada y sin decimales
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    // return sum.toLocaleString('es');
    return sum.toLocaleString('de-DE');
  }

  public cuentaCeldas(cells: any[]) {
    // Devuelve el número de filas
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + 1, 0);
    return sum.toLocaleString('de-DE');
  }



  // ** Selección de líneas  ** //

  onActivate(event: any) {
    // Evitar selección en caso de doble click
    if (event.type === 'dblclick') {
      event.rowElement.classList.remove('datatable-row-selected'); // Desmarca la fila

      this.filtraEstados8();
      this.setCuentaClientes();
      return;
    }

    if (event.type === 'click') {
      console.log('Activate Evento click', event);
      this.selectAllRows = false;

      this.filtraEstados8();
      this.setCuentaClientes();
      console.log('_selected.length', this.selected.length);
    }
  }


  toggleSelectAllRows() {
    // Establecer todos los elementos como seleccionados?
    const allRows = [...this.rows];

    if (this.selectAllRows) this.selected = [...allRows];
    if (!this.selectAllRows) this.selected = [];

    this.filtraEstados8();
    this.setCuentaClientes();
  }




  filtraEstados8() {
    // No seleccionar los que tengan estado == 8x
    this.selected = this.selected.filter(
      (item: { estado: number }) => !item.estado.toString().startsWith('8')
    );
  }

  setCuentaClientes() {
    this.cuentaClientes = this.selected.reduce((count: { add: (arg0: any) => any; }, pedido: { id_cliente: any; }) => {
      return count.add(pedido.id_cliente!);
    }, new Set<number>()).size;
  }



  //** Línea de Pedidos *// 


  delPedido(event: any, row: any) {
    event.stopPropagation()
    this.infoPedido(event, row)

    Swal.fire({
      title: '¿Borrar pedido del cliente?',
      icon: 'question',
      html: `Eliminar el pedido <b>${row.pedido_cli} de ${row.cliNombre}</b>`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.pedidosExternosSrv.delete(row.id)
          .subscribe(resp => {
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
            } else {
              this.notificacionesSrv.aviso('info', `Registro eliminado`);
              this.rows = this.rows.filter((objeto: any) => objeto.id !== row.id);
            }
          })
      }
    })

  }


  infoPedido(event: any, row: any) {
    event.stopPropagation()
    this.pedidosList = [];
    if (row.detall) this.pedidosList = row.detall;

    this.pedidoInfo.pedidoCli = row.pedido_cli;
    this.pedidoInfo.cliNombre = row.cliNombre;
    this.pedidoInfo.estado = row.estado;

    // Destacar línea seleccionada en negrita
    this.rows.forEach((r: any) => {
      r.destacarLinea = false;
    });
    row.destacarLinea = true;

    console.log('__infoPedido', row);
  }
}
