<div [class.oculto]="modalRegularizarSrv.ocultarModal"
     class="fondo-modal-imagen ">

    <div class="modal fade show"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel1"
         style="display: block;"
         (click)="cerrarModalClickOutside($event)">
        <div class="modal-dialog"
             role="document">

            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"
                        id="exampleModalLabel1">
                        <i class="fa fa-arrow-down-up-across-line"></i> Regularizar stock en {{modalRegularizarSrv.stock?.ubiCodigo | mask: '00.00.00.00.00.00'}}
                    </h4>
                    <button type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close">
                        <span (click)="cerrarModal()"
                              aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card card-body">
                                <h6 class="card-subtitle"> {{modalRegularizarSrv.stock?.descripcion}}</h6>

                                <form [formGroup]="regulForm"
                                      (ngSubmit)="enviar()"
                                      class="form-horizontal mt-1">


                                    <!-- cantidad -->
                                    <div class="col-12">
                                        <div class="form-group"
                                             [ngClass]="{ 'has-danger': enviado && f['cantidad'].errors }">
                                            <label class="form-control-label">Cantidad:</label>
                                            <input type="number"
                                                   formControlName="cantidad"
                                                   class="form-control"
                                                   [ngClass]="{ 'form-control-danger': enviado && f['cantidad'].errors }">

                                            <div *ngIf="enviado && f['cantidad'].errors"
                                                 class="form-control-feedback">
                                                <div *ngIf="f['cantidad'].errors['min']">Máximo -500 unidades</div>
                                                <div *ngIf="f['cantidad'].errors['max']">Máximo 500 unidades</div>
                                                <div *ngIf="regulForm.get('cantidad')?.errors?.['apiError']">{{regulForm.get('cantidad')?.errors?.['apiError'] }}</div>
                                            </div>
                                        </div>
                                    </div>


                                    <!-- descripcion -->
                                    <div class="col-12">
                                        <div class="form-group"
                                             [ngClass]="{ 'has-danger': enviado && f['descripcion'].errors }">
                                            <label class="form-control-label">Descripción:</label>
                                            <input type="text"
                                                   formControlName="descripcion"
                                                   class="form-control"
                                                   [ngClass]="{ 'form-control-danger': enviado && f['descripcion'].errors }">

                                            <div *ngIf="enviado && f['descripcion'].errors"
                                                 class="form-control-feedback">
                                                <div *ngIf="f['descripcion'].errors['required']">La descripción es necesaria para incluirla en transacciones</div>
                                                <div *ngIf="f['descripcion'].errors['minlength']">Debe contener un mínimo de 4 caracteres</div>
                                                <div *ngIf="f['descripcion'].errors['maxlength']">La descripción puede exceder de 150 caracteres</div>
                                                <div *ngIf="regulForm.get('motivo')?.errors?.['apiError']">
                                                    {{regulForm.get('descripcion')?.errors?.['apiError'] }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex">
                                        <button (click)="cerrarModal()"
                                                class="btn waves-effect waves-light btn-rounded btn-secondary"
                                                data-dismiss="modal">Cancelar</button>

                                        <button [disabled]="loading"
                                                type="submit"
                                                class="ml-auto btn waves-effect waves-light btn-rounded btn-success"> <i class="fa fa-spinner fa-spin"
                                               *ngIf="loading"></i> Enviar</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>


</div>