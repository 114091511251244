import { Component, OnInit } from '@angular/core';

// interfaces
import { ICliente } from 'src/app/interfaces/cliente';
import { IFacturacion } from 'src/app/interfaces/facturacion';

// servicios
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { FacturacionService } from 'src/app/services/datos/facturacion.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { environment } from 'src/environments/environment';

// librerías externas
import Swal from 'sweetalert2';

@Component({
  selector: 'app-facturacion',
  templateUrl: './facturacion.component.html',
  styleUrls: ['./facturacion.component.scss']
})
export class FacturacionComponent implements OnInit {

  public loading: boolean = false;
  public enviado: boolean = false;
  datosCabecera: IFacturacion = {}

  public clientesList: ICliente[] = [];
  cliSeleccionado: string = '';

  fechaDesde: string = '';
  fechaHasta: string = '';

  // Errores
  public errores: any = {}

  // Totales desde los hijos
  totalesGlobal: any = {};

  totalEntradas: number = 0;
  totalDevoluciones: number = 0;
  totalSalidas: number = 0;
  totalAlmacenaje: number = 0;
  totalSeguros: number = 0;

  granTotal: number = 0;



  constructor(
    private usuarioSrv: UsuarioService,
    private clientesSrv: ClientesService,
    private facturaSrv: FacturacionService,
    private notificacionesSrv: NotificacionesService
  ) { }

  ngOnInit(): void {
    this.granTotal = 0;
    this.cargaClientes()

    const currentDate = new Date();

    // Primer día del mes
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    this.fechaDesde = this.formatDate(firstDay);

    // Último día del mes
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    this.fechaHasta = this.formatDate(lastDay);
  }

  enviar() {
    this.enviado = true;

    // validar formulario
    this.errores = {}

    if (!this.cliSeleccionado) this.errores.cliSeleccionado = 'Requerido';
    if (!this.fechaDesde) this.errores.fechaDesde = 'Requerido';
    if (!this.fechaHasta) this.errores.fechaHasta = 'Requerido';

    if (this.fechaDesde && this.fechaHasta && new Date(this.fechaHasta) < new Date(this.fechaDesde)) {
      this.errores.fechaHasta = 'Debe ser mayor o igual a la fecha desde';
    }

    if (Object.keys(this.errores).length) {
      console.log('__enviat() ERRORES: ', Object.keys(this.errores).length);
      return
    }

    // Todo ok 

    this.datosCabecera = {
      'id_usuario': this.usuarioSrv.userdata.id!,
      'id_cliente': this.cliSeleccionado,
      'fechaDesde': this.fechaDesde,
      'fechaHasta': this.fechaHasta
    }

    console.log('__enviar() OK: ', this.datosCabecera);

  }

  confirmaClick() {
    Swal.fire({
      title: '¿Enviar datos a DTX?',
      icon: 'question',
      html: `Se van a enviar estos datos de facturación a DTX `,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('__confirmaClick(), enviar a la API de DTX', this.datosCabecera);
      }
    })
  }

  cargaClientes() {
    this.loading = true
    this.clientesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.clientesList = respuesta.data;
      })
  }


  onClienteSelect(id: string) {
    this.cliSeleccionado = id;
  }


  generarPdfClick() {
    const datos = {
      'id_usuario': this.usuarioSrv.userdata.id!,
      'cliente': this.totalesGlobal.seguros,
      'fechaDesde': this.fechaDesde,
      'fechaHasta': this.fechaHasta,

      'almacenaje': this.totalesGlobal.almacenaje,
      'devoluciones': this.totalesGlobal.devoluciones,
      'entradas': this.totalesGlobal.entradas,
      'salidas': this.totalesGlobal.salidas
    }
    console.log('__generarPdfClick() datos', datos);

    this.loading = true;
    this.facturaSrv.facPdfGlobal(datos).subscribe(resp => {
      this.loading = false;

      console.log('__generarPdfClick() resp', resp);

      if (resp.error) {
        this.notificacionesSrv.aviso('error', resp.mensaje);
        return;
      }

      const urlPdf: string = environment.pdfDir + '/facturacion/' + resp.totales.pdfName;
      window.open(urlPdf, '_blank');

    });
  }


  // Captar los totales emitidos desde los hijos

  handleTotalEntradas(value: any) {
    this.totalesGlobal.entradas = value;
    this.sumaTotales()
    console.log('__handleTotalEntradas() totalesGlobal:', this.totalesGlobal);
  }

  handleTotalDevoluciones(value: any) {
    this.totalesGlobal.devoluciones = value;
    this.sumaTotales()
    console.log('__handleTotalDevoluciones() totalesGlobal:', this.totalesGlobal);
  }

  handleTotalSalidas(value: any) {
    this.totalesGlobal.salidas = value;
    this.sumaTotales()
    console.log('__handleTotalSalidas() totalesGlobal:', this.totalesGlobal);
  }

  handleTotalAlmacenaje(value: number) {
    this.totalesGlobal.almacenaje = value;
    this.sumaTotales()
    console.log('__handleTotalAlmacenaje() totalAlmacenaje:', this.totalesGlobal);
  }


  handleTotalSeguros(value: number) {
    this.totalesGlobal.seguros = value;
    this.sumaTotales()
    console.log('__handleTotalSeguros() totalSeguro:', this.totalSeguros);
  }



  sumaTotales() {

    // Verificar si los totales ya están definidos
    this.totalEntradas = this.totalesGlobal.entradas?._sumas?.Importe ?? 0;
    this.totalDevoluciones = this.totalesGlobal.devoluciones?._sumas?.Importe ?? 0;
    this.totalSalidas = this.totalesGlobal.salidas?._sumas?.Importe ?? 0;
    this.totalAlmacenaje = this.totalesGlobal.almacenaje?.almacenaje._sumas?.Importe ?? 0;
    this.totalSeguros = this.totalesGlobal.seguros?.fac_seguro_total ?? 0;

    this.granTotal = this.totalEntradas
      + this.totalDevoluciones
      + this.totalSalidas
      + this.totalAlmacenaje
      + this.totalSeguros
  }

  /// AUX ////

  // Formatea una fecha como yyyy-mm-dd
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Número de errores en el formulario
  getErroresLength(): number {
    return Object.keys(this.errores).length;
  }

}
