<div class="row animated fadeIn fast">

    <div class="col-lg-12">
        <div class="card">

            <div class="card-body">
                <form class="form p-t-20"
                      [formGroup]="cliForm"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">
                    <div class="form-body">

                        <div class="row">

                            <!-- nombre -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['nombre'].errors }">
                                    <label class="form-control-label">Nombre:</label>
                                    <input type="text"
                                           formControlName="nombre"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['nombre'].errors }">

                                    <div *ngIf="enviado && f['nombre'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['nombre'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['nombre'].errors['minlength']">Debe contener un mínimo de 4 caracteres</div>
                                        <div *ngIf="f['nombre'].errors['maxlength']">Nombre no puede exceder de 150 caracteres</div>
                                        <div *ngIf="cliForm.get('nombre')?.errors?.['apiError']">{{cliForm.get('nombre')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>




                            <!-- contacto -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['contacto'].errors }">
                                    <label class="form-control-label">Persona de contacto:</label>
                                    <input type="text"
                                           formControlName="contacto"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['nombre'].errors }">

                                    <div *ngIf="enviado && f['contacto'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['contacto'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['contacto'].errors['minlength']">Debe contener un mínimo de 4 caracteres</div>
                                        <div *ngIf="f['contacto'].errors['maxlength']">Nombre no puede exceder de 150 caracteres</div>
                                        <div *ngIf="cliForm.get('contacto')?.errors?.['apiError']">{{cliForm.get('contacto')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>


                            <!-- id_tarifa -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['id_tarifa'].errors }">
                                    <label class="form-control-label">Tarifa:</label>
                                    <select formControlName="id_tarifa"
                                            class="form-control"
                                            [ngClass]="{ 'form-control-danger': enviado && f['id_tarifa'].errors }">
                                        <option *ngFor="let tarifa of tarifasList"
                                                [value]="tarifa.id">
                                            {{tarifa.descripcion}}
                                        </option>
                                    </select>
                                    <div *ngIf="enviado && f['id_tarifa'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="cliForm.get('id_tarifa')?.errors?.['apiError']">{{
                                            cliForm.get('id_tarifa')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>

                        </div> <!-- row -->


                        <div class="row">

                            <!-- direccion -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['direccion'].errors }">
                                    <label class="form-control-label">Dirección:</label>
                                    <input type="text"
                                           formControlName="direccion"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['direccion'].errors }">

                                    <div *ngIf="enviado && f['direccion'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['direccion'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['direccion'].errors['minlength']">Como mínimo 6 caracteres</div>
                                        <div *ngIf="f['direccion'].errors['maxlength']">Como máximo 150 caracteres</div>
                                        <div *ngIf="cliForm.get('direccion')?.errors?.['apiError']">{{cliForm.get('direccion')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>



                            <!-- cp -->
                            <div class="form-group col-md-1">
                                <div class="form-floating mb-3">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger':   f['cp'].errors }">
                                        <label class="form-control-label">cp:</label>
                                        <input formControlName="cp"
                                               class="form-control"
                                               [ngClass]="{ 'form-control-danger': f['cp'].errors }"
                                               maxlength="5"
                                               (keyup)="buscaPob()" />

                                        <div *ngIf="f['cp'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['cp'].errors['noExiste']">No existe</div>
                                            <div *ngIf="cliForm.get('cp')?.errors?.['apiError']">{{cliForm.get('cp')?.errors?.['apiError'] }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- poblacion -->
                            <div class="form-group col-md-3">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['poblacion'].errors }">
                                    <label class="form-control-label">Población:</label>
                                    <select formControlName="poblacion"
                                            class="form-control"
                                            [ngClass]="{ 'form-control-danger': enviado && f['poblacion'].errors }">
                                        <option *ngFor="let pob of listaPob"
                                                [value]="pob">
                                            {{pob}}
                                        </option>
                                    </select>
                                    <div *ngIf="enviado && f['poblacion'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="cliForm.get('poblacion')?.errors?.['apiError']">{{
                                            cliForm.get('poblacion')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>

                            </div>

                            <!-- provincia -->
                            <div class="form-group col-md-2">
                                <label for="strProOri">Provincia:</label>
                                <input class="form-control readonly"
                                       formControlName="provincia"
                                       readonly>
                            </div>



                        </div> <!-- row -->


                        <div class="row">


                            <div class="col-md-3">
                                <div class="form-group">
                                    <!-- foto -->
                                    <span *ngIf="cliente.id">

                                        <label class="form-control-label ml-4"
                                               data-toggle="tooltip"
                                               title="Banner qe se usará en los pdf de los ALBARANES">
                                            Banner: (1000 x 300)
                                            <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                        </label>

                                        <div class="text-center shadow mt4">
                                            <img [src]="fotoDir +'/clientes/'+ cliente.foto"
                                                 loading="lazy"
                                                 (click)="abrirModal(cliente)"
                                                 alt="Banner"
                                                 title="Modificar"
                                                 class="img-thumbnail img-avatar pointer mt-1">
                                        </div>
                                    </span>
                                </div>
                            </div>

                            <!-- email -->
                            <div class="col-md-3">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['email'].errors }">
                                    <label class="form-control-label">Email:</label>
                                    <input type="text"
                                           formControlName="email"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['email'].errors }">

                                    <div *ngIf="enviado && f['email'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['email'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['email'].errors['eMailFormat']">No parece un eMail válido</div>
                                        <div *ngIf="cliForm.get('email')?.errors?.['apiError']">{{cliForm.get('email')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>


                            <!-- telefono -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['telefono'].errors }">
                                    <label class="form-control-label">Teléfono:</label>
                                    <input type="text"
                                           formControlName="telefono"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['telefono'].errors }">
                                    <div *ngIf="enviado && f['telefono'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['telefono'].errors['required']">Obligatorio</div>
                                        <div *ngIf="cliForm.get('telefono')?.errors?.['apiError']">{{cliForm.get('telefono')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>


                            <!-- url -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['url'].errors }">
                                    <label class="form-control-label">Web:</label>
                                    <input type="text"
                                           formControlName="url"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['url'].errors }">

                                    <div *ngIf="enviado && f['url'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['url'].errors['urlValid']">No parece una dirección url válida</div>
                                        <div *ngIf="cliForm.get('url')?.errors?.['apiError']">{{cliForm.get('url')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>


                        </div> <!-- row -->


                        <div class="row">
                            <div class="col-12">
                                <small class="text-info mb-0">Almacén</small>
                                <hr class="text-info mt-0 mb-2">
                            </div>
                        </div>



                        <div class="row">



                            <!-- alm_por_defecto -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['alm_por_defecto'].errors }">
                                    <label class="form-control-label">Almacen predeterminado</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"> <i class="fa-solid fa-warehouse"></i></div>

                                        <select formControlName="alm_por_defecto"
                                                class="form-control"
                                                [ngClass]="{ 'form-control-danger': enviado && f['alm_por_defecto'].errors }">
                                            <option value="">Selecciona...</option>
                                            <option *ngFor="let alm of almacenesList"
                                                    [value]="alm.id">
                                                {{alm.nombre}}
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="enviado && f['alm_por_defecto'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="cliForm.get('alm_por_defecto')?.errors?.['apiError']">{{
                                            cliForm.get('alm_por_defecto')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>



                            <!-- almacen_desde -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['almacen_desde'].errors }">
                                    <label class="form-control-label">Reservar espacio desde ubicación:</label>
                                    <input type="text"
                                           mask="00.00.00.00.00.00"
                                           (input)="cambiaUbiDesde()"
                                           formControlName="almacen_desde"
                                           class="form-control grande"
                                           [ngClass]="{ 'form-control-danger': enviado && f['almacen_desde'].errors }">
                                    <div *ngIf="enviado && f['almacen_desde'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['almacen_desde'].errors['minlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['almacen_desde'].errors['maxlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['almacen_desde'].errors?.['apiError']">{{cliForm.get('almacen_desde')?.errors?.['apiError']}}</div>
                                    </div>
                                    <small>{{infoUbiDesde}}</small>
                                </div>
                            </div>

                            <!-- almacen_hasta -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['almacen_hasta'].errors }">
                                    <label class="form-control-label">Reservar espacio hasta ubicación:</label>
                                    <input type="text"
                                           (input)="cambiaUbiHasta()"
                                           mask="00.00.00.00.00.00"
                                           formControlName="almacen_hasta"
                                           class="form-control grande"
                                           [ngClass]="{ 'form-control-danger': enviado && f['almacen_hasta'].errors }">
                                    <div *ngIf="enviado && f['almacen_hasta'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['almacen_hasta'].errors['minlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['almacen_hasta'].errors['maxlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['almacen_hasta'].errors?.['apiError']">{{cliForm.get('almacen_hasta')?.errors?.['apiError']}}</div>
                                    </div>
                                    <small>{{infoUbiHasta}}</small>
                                </div>
                            </div>


                        </div> <!-- row -->


                        <div class="row">
                            <div class="col-12">
                                <small class="text-info mb-0">Pedidos</small>
                                <hr class="text-info mt-0 mb-2">
                            </div>
                        </div>


                        <div class="row">


                            <!-- conta_campanya -->
                            <div class="form-group col-md-4">
                                <label for="strProOri">Última campaña:</label>
                                <input class="form-control readonly"
                                       formControlName="conta_campanya"
                                       readonly>
                            </div>


                            <!-- lifo_fifo -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['lifo_fifo'].errors }">
                                    <label class="form-control-label">LiFo/FiFo:</label>
                                    <select formControlName="lifo_fifo"
                                            class="form-control"
                                            [ngClass]="{ 'form-control-danger': enviado && f['lifo_fifo'].errors }">
                                        <option [value]="'lifo'">LiFo</option>
                                        <option [value]="'fifo'">FiFo</option>
                                        <option [value]="''">Indiferente</option>
                                    </select>
                                    <div *ngIf="enviado && f['lifo_fifo'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="cliForm.get('lifo_fifo')?.errors?.['apiError']">{{
                                            cliForm.get('lifo_fifo')?.errors?.['apiError'] }}</div>
                                    </div>
                                    <small><i> Orden:</i>
                                        Lote-Fecha caducidad +
                                        FiFo o LiFo +
                                        Peso
                                    </small>
                                </div>
                            </div>


                        </div> <!-- row -->


                        <div class="row">
                            <div class="col-12">
                                <small class="text-info mb-0">Intranet</small>
                                <hr class="text-info mt-0 mb-2">
                            </div>
                        </div>


                        <div class="row">


                            <!-- acceso_intranet -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['acceso_intranet'].errors }">
                                    <div class="custom-control custom-switch">
                                        <input formControlName="acceso_intranet"
                                               type="checkbox"
                                               class="custom-control-input"
                                               id="acceso_intranet">
                                        <label class="custom-control-label"
                                               for="acceso_intranet">Acceso a Intranet de cliente</label>
                                    </div>
                                </div>
                            </div>


                            <!-- agrupa_ubicaciones -->
                            <div class="col-md-4"
                                 *ngIf="cliForm.get('acceso_intranet')?.value">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['agrupa_ubicaciones'].errors }">
                                    <div class="custom-control custom-switch">
                                        <input formControlName="agrupa_ubicaciones"
                                               type="checkbox"
                                               class="custom-control-input"
                                               id="agrupa_ubicaciones">
                                        <!-- <label class="custom-control-label"
                                               for="agrupa_ubicaciones">Agrupar ubicaciones del Stock</label> -->


                                        <label class="custom-control-label"
                                               for="agrupa_ubicaciones"
                                               data-toggle="tooltip"
                                               title="El cliente verá su Stock agrupado, sin diferenciar entre ubicaciones de un mismo artículo">
                                            Agrupar ubicaciones del Stock
                                            <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                        </label>

                                    </div>
                                </div>
                            </div>




                            <!-- emaemail_intranetil -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['email_intranet'].errors }">
                                    <label class="form-control-label">Email de recepción de solicitudes:</label>
                                    <input type="text"
                                           formControlName="email_intranet"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['email'].errors }">

                                    <div *ngIf="enviado && f['email_intranet'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['email_intranet'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['email_intranet'].errors['eMailFormat']">No parece un eMail válido</div>
                                        <div *ngIf="cliForm.get('email_intranet')?.errors?.['apiError']">{{cliForm.get('email_intranet')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>





                        </div> <!-- row  -->


                        <div class="row">

                            <!-- usuariosList -->
                            <div class="col-md-4"
                                 *ngIf="cliForm.get('acceso_intranet')?.value">
                                <div class="form-group">
                                    <label class="form-control-label"
                                           data-toggle="tooltip"
                                           title="Usuarios con acceso a la intranet para este Cliente">
                                        Usuarios Intranet:
                                        <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                    </label>

                                    <div *ngIf="usuariosList?.length! > 0; else noUsuarios">
                                        <table class="table table-sm table-bordered rounded border-5">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Usuario</th>
                                                    <th>Rol</th>
                                                    <th>Editar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let usuario of usuariosList">
                                                    <td>
                                                        <img [src]="fotoDir +'/users/'+ usuario.foto"
                                                             loading="lazy"
                                                             class="rounded-circle"
                                                             style="width:30px" />
                                                    </td>
                                                    <td>{{usuario.username}}</td>
                                                    <td>
                                                        <span class="badge"
                                                              [ngClass]="{
                                                                'badge-warning': usuario.rolIntranet === 'admin', 
                                                                'badge-light': usuario.rolIntranet !== 'admin'
                                                                }"> {{usuario.rolIntranet}}</span>
                                                    </td>
                                                    <td>
                                                        <a routerLink="/panel/usuario/{{usuario.id}}"
                                                           class="text-secondary">
                                                            <i class="fa-solid fa-pen-to-square"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <ng-template #noUsuarios>
                                        <ul>
                                            <li>
                                                Sin usuarios asignados
                                            </li>
                                        </ul>
                                    </ng-template>

                                </div>
                            </div>


                            <!-- codServicio_pred -->
                            <div class="col-md-4"
                                 *ngIf="cliForm.get('acceso_intranet')?.value">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['codServicio_pred'].errors }">
                                    <label class="form-control-label"
                                           data-toggle="tooltip"
                                           title="Servicio por defecto al crear nuevos pedidos externos desde la Intranet de Cliente">
                                        Servicio predetermiado:
                                        <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                    </label>
                                    <select formControlName="codServicio_pred"
                                            class="form-control"
                                            [ngClass]="{ 'form-control-danger': enviado && f['codServicio_pred'].errors }">
                                        <option class=" font-bold"
                                                value="0">Indiferente</option>
                                        <option *ngFor="let servicio of serviciosList"
                                                [value]="servicio.codServicio">
                                            {{servicio.nombre}}
                                        </option>
                                    </select>
                                    <div *ngIf="enviado && f['codServicio_pred'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="cliForm.get('codServicio_pred')?.errors?.['apiError']">{{
                                            cliForm.get('codServicio_pred')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>



                        </div> <!-- row -->


                        <hr>

                        <div class="d-flex">
                            <button [disabled]="loading"
                                    type="button"
                                    class="btn waves-effect waves-light btn-rounded btn-secondary"
                                    [routerLink]="'/panel/clientes_lista'"
                                    data-dismiss="modal">Cancelar</button>
                            <h6 class="card-subtitle ml-4 ">
                                <li *ngIf="cliente.created_at"> Creado hace {{cliente.created_at! | friendlyDdmmyy}}</li>
                                <li *ngIf="cliente.updated_at"> Última modificación hace {{cliente.updated_at! | friendlyDdmmyy}}</li>
                            </h6>
                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Enviar</button>
                        </div>

                    </div>

                </form>
            </div>
        </div>
    </div>

</div>