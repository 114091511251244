import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'campanyaEstadoPreparacion'
})
export class CampanyaEstadoPreparacionPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    // 1 pendiente, 2 empezado, 3- Completo, 8 error

    switch (value) {
      case '1':
        return '<span class="text-info">Pendiente</span>';
        break;
      case '2':  
        return '<span class="text-warning">En proceso</span>';
        break;
      case '3':
        return '<span class="text-success">Finalizada</span>';
        break;
      case '8':
        return '<span class="text-danger">Incidencia</span>';
        break;
    }
    return value;

  }

}
