// Módulos
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Servicios
import { UsuarioService } from './usuario.service';
import { NotificacionesService } from '../notificaciones.service';

// Interfaces y modelos
import { ITarifa } from 'src/app/interfaces/tarifa';
import { IApiResp } from 'src/app/interfaces/api-resp';

// Librerías
import { environment } from 'src/environments/environment';
import { catchError, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TarifasService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }

  getLista() {
    // Devulve la lista de tarifas
    const path = `${this.apiURL}/tarifas/list`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  update(tarifa: ITarifa) {
    const path = `${this.apiURL}/tarifas/update/${tarifa.id}`;

    return this.http.post<IApiResp>(path, tarifa)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  delete(id: number) {
    const path = `${this.apiURL}/tarifas/delete/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }


}
